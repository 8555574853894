import store from '../../store';
import pipeline from './pipeline';

function middleware(to, from, next) {
  if (!to.meta.middleware) {
    return next();
  }
  const { middleware } = to.meta;
  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    next: pipeline(context, middleware, 1),
  });
}
export default middleware;
