<template>
  <v-container>
    <h1>Icon Library</h1>
    <v-row>
      <v-col
        v-for="(icon, iconName) in icons"
        :key="iconName"
        cols="3"
        class="text-center"
      >
        <v-icon class="mb-2" >
          ${{ iconName }}
        </v-icon>
        <div>{{ iconName }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SRP_ICONS from '@/components/icons';

export default {
  data() {
    return {
      icons: SRP_ICONS,
    };
  },
};
</script>

<style scoped>
.v-icon {
  font-size: 40px;
}
</style>
