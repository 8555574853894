<template>
  <svg class="srp-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.80273 10.1985C7.38873 10.1985 7.05273 9.86249 7.05273 9.44849V7.30149C7.06573 4.36349 9.44173 2.00049 12.3547 2.00049H12.3747C14.6307 2.00049 16.6447 3.43349 17.3837 5.56749C17.5197 5.95849 17.3117 6.38549 16.9197 6.52149C16.5287 6.65849 16.1017 6.44949 15.9657 6.05749C15.4367 4.52849 13.9917 3.50049 12.3717 3.50049H12.3577C10.2657 3.50049 8.56173 5.19449 8.55273 7.28449V9.44849C8.55273 9.86249 8.21673 10.1985 7.80273 10.1985Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.542 10.127C6.864 10.127 5.5 11.492 5.5 13.17V17.458C5.5 19.136 6.864 20.501 8.542 20.501H16.184C17.861 20.501 19.226 19.136 19.226 17.458V13.17C19.226 11.492 17.861 10.127 16.184 10.127H8.542ZM16.184 22.001H8.542C6.037 22.001 4 19.963 4 17.458V13.17C4 10.665 6.037 8.62695 8.542 8.62695H16.184C18.688 8.62695 20.726 10.665 20.726 13.17V17.458C20.726 19.963 18.688 22.001 16.184 22.001Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3633 17.1751C11.9493 17.1751 11.6133 16.8391 11.6133 16.4251V14.2031C11.6133 13.7891 11.9493 13.4531 12.3633 13.4531C12.7773 13.4531 13.1133 13.7891 13.1133 14.2031V16.4251C13.1133 16.8391 12.7773 17.1751 12.3633 17.1751Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'UnlockIcon',
};
</script>
