import axios from 'axios';

export const login = ({ username, password, language }) => axios({
  url: '/login',
  method: 'POST',
  data: { username, password, language },
});

export const logout = () => axios({
  url: '/logout',
  method: 'POST',
});

export const changeLanguage = (data) => axios({
  url: '/change-language',
  method: 'PUT',
  data: { language: data },
});
