import addIcon from './add-icon';
import administration from './administration-icon';
import attention from './attention-icon';
import calendar from './calendar-icon';
import check from './check-icon.vue';
import checkboxOff from './checkboxOff-icon';
import checkboxOn from './checkboxOn-icon';
import closeIcon from './close-icon';
import closeCircle from './closeCircle-icon';
import copy from './copy-icon';
import deleteIcon from './delete-icon';
import document from './document-icon';
import down from './down-icon';
import download from './download-icon';
import edit from './edit-icon';
import equipment from './equipment-icon';
import filter from './filter-icon';
import hamburger from './hamburger-icon';
import hide from './hide-icon';
import home from './home-icon';
import left from './left-icon';
import linkIcon from './link-icon';
import location from './location-icon';
import lock from './lock-icon';
import logout from './logout-icon';
import mail from './mail-icon';
import paper from './paper-icon';
import paperDownload from './paperDownload-icon';
import paperReplaceIcon from './paperReplace-icon';
import paperUpload from './paperUpload-icon';
import password from './password-icon';
import plus from './plus-icon';
import profile from './profile-icon';
import question from './question-icon';
import radioOff from './radioOff-icon';
import radioOn from './radioOn-icon';
import report from './report-icon';
import back from './back-icon';
import right from './right-icon';
import search from './search-icon';
import settings from './settings-icon';
import showIcon from './show-icon';
import swapIcon from './swap-icon';
import telephone from './telephone-icon';
import unlock from './unlock-icon';
import up from './up-icon.vue';
import upload from './upload-icon.vue';
import user from './user-icon';
import ellipsisIcon from './ellipsis-icon';
import writingIcon from './writing-icon';
import objects from './objects-icon';
import device from './device-icon';
import gateIcon from './gate-icon';
import addSquareIcon from './add-square-icon';
import informationIcon from './information-icon';
import squareWithCirclesIcon from './square-with-circles-icon';
import pictureIcon from './picture-icon.vue';
import changeArrowsIcon from './change-arrows-icon.vue';
import videoIcon from './video-icon.vue';
import infomaticsIcon from './infomatics-icon.vue';
import arrowIcon from './arrow-icon.vue';
import notFoundIcon from './not-found-icon.vue';
import searchQueryIcon from './search-query-icon.vue';
import doubleAltArrowIcon from './double-alt-arrow-icon.vue';
import timeIcon from './time-icon.vue';
import carIcon from './car-icon.vue';
import printerIcon from './printer-icon.vue';

const SRP_ICONS = {
  add: { component: addIcon },
  administration: { component: administration },
  attention: { component: attention },
  back : {component: back},
  calendar: { component: calendar },
  check: { component: check },
  checkboxOff: { component: checkboxOff },
  checkboxOn: { component: checkboxOn },
  close: { component: closeIcon },
  closeCircle: { component: closeCircle },
  copy: { component: copy },
  delete: { component: deleteIcon },
  document: { component: document },
  down: { component: down },
  download: { component: download },
  edit: { component: edit },
  equipment: { component: equipment },
  filter: { component: filter },
  hamburger: { component: hamburger },
  hide: { component: hide },
  home: { component: home },
  left: { component: left },
  link: { component: linkIcon },
  location: { component: location },
  lock: { component: lock },
  logout: { component: logout },
  mail: { component: mail },
  next: { component: down, props: { mode: 'next' } },
  paper: { component: paper },
  paperDownload: { component: paperDownload },
  paperReplace: { component: paperReplaceIcon },
  paperUpload: { component: paperUpload },
  password: { component: password },
  plus: { component: plus },
  prev: { component: down, props: { mode: 'prev' } },
  profile: { component: profile },
  question: { component: question },
  radioOff: { component: radioOff },
  radioOn: { component: radioOn },
  report: {component: report},
  right: { component: right },
  search: { component: search },
  settings: { component: settings },
  show: { component: showIcon },
  swap: { component: swapIcon },
  telephone: { component: telephone },
  unlock: { component: unlock },
  up: { component: up },
  upload: { component: upload },
  user: { component: user },
  ellipsisIcon: { component: ellipsisIcon },
  writingIcon: { component: writingIcon },
  objects: { component: objects },
  device: { component: device },
  gateIcon: { component: gateIcon },
  addSquareIcon: { component: addSquareIcon },
  informationIcon: { component: informationIcon },
  squareWithCirclesIcon: { component: squareWithCirclesIcon },
  pictureIcon: { component: pictureIcon },
  changeArrowsIcon: { component: changeArrowsIcon },
  videoIcon: {  component: videoIcon },
  infomaticsIcon: {component: infomaticsIcon},
  arrowIcon: {component: arrowIcon},
  notFoundIcon: {component: notFoundIcon},
  searchQueryIcon: {component: searchQueryIcon},
  doubleAltArrowIcon: {component: doubleAltArrowIcon},
  timeIcon: {component: timeIcon},
  carIcon: {component: carIcon},
  printerIcon: {component: printerIcon},
};

export default SRP_ICONS;
