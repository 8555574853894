<script>
import {
  REQUEST_STATUSES,
} from '@/constants/index.js';
import { findObjectInArrayAndGetItsValue } from '@/helpers/helpers.js';
import SelectedCarItem from './SelectedCarItem.vue';

export default {
  components: { SelectedCarItem },
  name: 'SelectedCarList',
  props: {
    filteredSelectedCar: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      REQUEST_STATUSES,
      boxInfo: true,
    }
  },
  computed: {
    computedStatuses() {
      return this.filteredSelectedCar.map(car => {
        const foundStatus = findObjectInArrayAndGetItsValue({
          arr: this.REQUEST_STATUSES,
          obj: car,
          arrKey: 'value',
          objKey: 'STATE'
        });

        return {
          ...car,
          colorClassName: foundStatus?.colorClassName || '',
          backgroundColor: foundStatus?.backgroundColor || '',
        };
      });
    }
  },
  methods: {
    updateSelectedCar(item) {
      this.$emit('updateSelectedCar', item);
    },
    editPass(car) {
      this.$emit('editPass', car);
    }
  }
}
</script>

<template>
  <div class="selected-car">
    <div class="selected-car__box-info" v-if="boxInfo">
      <div class="selected-car__box-info-text">
        {{ $t('Passes are shown') }} <strong>{{ $t('in the last 7 days') }}</strong>. {{ $t('The entire list can be viewed in the full version of the site') }}
      </div>
      <v-icon
        @click="boxInfo = false"
        class="selected-car__box-info-icon"
        size="10"
      >
        $close
      </v-icon>
    </div>

    <div class="selected-car__list">
      <div
        class="selected-car__item"
        v-for="car in computedStatuses"
        :key="car.ID"
      >
        <SelectedCarItem
          :filteredSelectedCar="filteredSelectedCar"
          :car=car
          @updateSelectedCar="updateSelectedCar"
          @editPass="editPass"
        ></SelectedCarItem>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
.selected-car {
  padding: 0 16px 26px;
  margin-top: 12px;

  &__box-info {
    display: flex;
    gap: 10px;
    padding: 8px;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 0 4px 13px 0 #8E8E8E40;
  }

  &__box-info-text {
    font-size: 12px;
    line-height: 120%;
    color: $text-secondary-gray;
  }

  & &__box-info-icon {
    color: $text-secondary-gray;
    fill: $text-secondary-gray;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    border: 1px solid $stroke;
    border-radius: 8px;
  }
}
</style>
