export const administrationMenu = {
  id: 1,
  name: 'Administration',
  icon: '$administration',
  submenu: [
    {
      id: 12,
      name: 'Users',
      link: '/administration/users',
    },
    {
      id: 19,
      name: 'Reference books',
      link: '/administration/reference-books',
    },
    {
      id: 21,
      name: 'Additionally',
      childrenSubmenu: [
        {
          id: 11,
          name: 'External systems',
          link: '/administration/external-systems',
        },
        {
          id: 13,
          name: 'Equipment',
          link: '/administration/equipments',
        },
        {
          id: 14,
          name: 'Access zones',
          link: '/administration/access-zones',
        },
        {
          id: 15,
          name: 'Gate',
          link: '/administration/gates',
        },
        {
          id: 16,
          name: 'Roles',
          link: '/administration/roles',
        },
        {
          id: 17,
          name: 'External readers',
          link: '/administration/external-readers',
        },
        {
          id: 18,
          name: 'Servers',
          link: '/administration/servers',
        },
        {
          id: 20,
          name: 'Settings',
          link: '/administration/settings',
        },
      ]
    }
  ],
};

export const objectMenu = {
  id: 2,
  name: 'Objects to visit',
  link: '/objects',
  icon: '$home',
};

export const requestsMenu = {
  id: 3,
  name: 'Passes',
  link: '/requests',
  icon: '$user',
};

export const passagesMenu = {
  id: 4,
  name: 'Event log',
  link: '/passages',
  icon: '$document',
};

export const reportMenu = {
  id: 5,
  name: 'Reports',
  link: '/reports',
  icon: '$report',
}
