<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_2969_16660"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="20"
      height="20"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 2H21.9999V22H2V2Z" />
    </mask>
    <g mask="url(#mask0_2969_16660)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.664 3.5C5.135 3.5 3.5 5.233 3.5 7.916V16.084C3.5 18.767 5.135 20.5 7.664 20.5H16.332C18.864 20.5 20.5 18.767 20.5 16.084V7.916C20.5 5.233 18.864 3.5 16.334 3.5H7.664ZM16.332 22H7.664C4.276 22 2 19.622 2 16.084V7.916C2 4.378 4.276 2 7.664 2H16.334C19.723 2 22 4.378 22 7.916V16.084C22 19.622 19.723 22 16.332 22Z"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.83838 10.8984C8.22837 10.8984 7.73438 11.3924 7.73438 12.0004C7.73438 12.6084 8.22837 13.1024 8.83637 13.1024C9.44338 13.1024 9.93837 12.6084 9.93837 12.0004C9.93837 11.3934 9.44438 10.8994 8.83838 10.8984ZM8.83637 14.6024C7.40138 14.6024 6.23438 13.4344 6.23438 12.0004C6.23438 10.5654 7.40138 9.39844 8.83637 9.39844C10.2734 9.39944 11.4384 10.5664 11.4384 12.0004C11.4384 13.4344 10.2714 14.6024 8.83637 14.6024Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0094 14.6025C16.5954 14.6025 16.2594 14.2665 16.2594 13.8525V12.7495H10.6914C10.2774 12.7495 9.94141 12.4145 9.94141 11.9995C9.94141 11.5855 10.2774 11.2495 10.6914 11.2495H17.0094C17.4234 11.2495 17.7594 11.5855 17.7594 11.9995V13.8525C17.7594 14.2665 17.4234 14.6025 17.0094 14.6025Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.1816 14.6025C13.7676 14.6025 13.4316 14.2665 13.4316 13.8525V11.9995C13.4316 11.5855 13.7676 11.2495 14.1816 11.2495C14.5956 11.2495 14.9316 11.5855 14.9316 11.9995V13.8525C14.9316 14.2665 14.5956 14.6025 14.1816 14.6025Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PasswordIcon',
};
</script>
