import axios from 'axios';

export default function initInterceptors() {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const errors = { ...error };
      if (errors.response && errors.response.status === 401) {
        localStorage.removeItem('token');
        document.location = '/';
      }

      return Promise.reject(errors);
    },
  );
}
