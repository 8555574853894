<script>
import api from '@/api';
import urls from '@/helpers/urls.js';

export default {
  name: 'ConsentPersonalDataCheckbox',
  inject: ['urls'],
  data() {
    return {
      agreeLink: urls.consentPersonalDataFile,
    };
  },
  mounted() {
    api.service.linkToAgree().then(({ data }) => {
      if (data.link) {
        this.agreeLink = data.link;
      }
    });
  },
};
</script>

<template>
  <v-checkbox
    :rules="[v => !!v || 'consentPersonalDataValidation']"
    required
    class="mt-0"
  >
    <template #label>
      <div>
        {{ $t('consentPersonalDataPart1') }}
        <a target="_blank" :href="agreeLink" @click.stop>
          {{ $t('consentPersonalDataPart2') }}
        </a>
      </div>
    </template>
    <template #message="{ message }">
      {{ $t(message) }}
    </template>
  </v-checkbox>
</template>
