import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/vueI18n';
import './plugins/vueMask';
import initInterceptors from './api/interceptors';
import VueCookies from 'vue-cookies';
Vue.config.productionTip = false;
initInterceptors();

Vue.directive('sticky', {
  inserted: function (el) {
    const onScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const triggerPoint = 60; // Установите точку, после которой элемент появится

      if (scrollTop >= triggerPoint) {
        el.style.display = 'block';
      } else {
        el.style.display = 'none';
      }
    };

    window.addEventListener('scroll', onScroll);

    el._onScroll = onScroll;
  },
  unbind: function (el) {
    window.removeEventListener('scroll', el._onScroll);
    delete el._onScroll;
  }
});

Vue.use(VueCookies, { expires: '30d' });
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
