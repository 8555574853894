<template>
  <svg
    class="srp-stroke-icon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.59151 13.2068C11.2805 13.2068 14.4335 13.7658 14.4335 15.9988C14.4335 18.2318 11.3015 18.8068 7.59151 18.8068C3.90151 18.8068 0.749512 18.2528 0.749512 16.0188C0.749512 13.7848 3.88051 13.2068 7.59151 13.2068Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.59157 10.0198C5.16957 10.0198 3.20557 8.05682 3.20557 5.63482C3.20557 3.21282 5.16957 1.24982 7.59157 1.24982C10.0126 1.24982 11.9766 3.21282 11.9766 5.63482C11.9856 8.04782 10.0356 10.0108 7.62257 10.0198H7.59157Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.4832 8.88165C16.0842 8.65665 17.3172 7.28265 17.3202 5.61965C17.3202 3.98065 16.1252 2.62065 14.5582 2.36365"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5955 12.7322C18.1465 12.9632 19.2295 13.5072 19.2295 14.6272C19.2295 15.3982 18.7195 15.8982 17.8955 16.2112"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'AdministrationIcon',
};
</script>
