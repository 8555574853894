import axios from 'axios';

export const getAll = (params) => axios({
  url: '/external-systems',
  method: 'GET',
  params,
});

export const create = (systemFormData) => axios({
  url: '/external-systems',
  method: 'POST',
  data: systemFormData,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const update = (id, systemFormData) => {
  // обход проблем с  Laravel
  systemFormData.append('_method', 'PUT');
  return axios({
    url: `/external-systems/${id}`,
    method: 'POST',
    data: systemFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const remove = (id) => axios({
  url: `/external-systems/${id}`,
  method: 'DELETE',
});
