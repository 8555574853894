import axios from 'axios';

export const getAll = () => axios({
  url: '/requests-config',
  method: 'GET',
});

export const update = (config_form) => axios({
  url: '/requests-config',
  method: 'PUT',
  data: {
    PARAMS: config_form,
  },
});
