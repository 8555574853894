import { COMMON_SET_LOADING } from '../types/mutation-types';

export default {
  state: {
    isLoading: false,
  },
  mutations: {
    [COMMON_SET_LOADING](state, isLoading = true) {
      state.isLoading = isLoading;
    },
  },
};
