<template>
  <svg class="srp-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.577 22.3686H7.753C4.312 22.3686 2 19.9536 2 16.3596V8.04562C2 4.45162 4.312 2.03662 7.753 2.03662H11.492C11.906 2.03662 12.242 2.37262 12.242 2.78662C12.242 3.20062 11.906 3.53662 11.492 3.53662H7.753C5.169 3.53662 3.5 5.30662 3.5 8.04562V16.3596C3.5 19.0986 5.169 20.8686 7.753 20.8686H16.577C19.161 20.8686 20.831 19.0986 20.831 16.3596V12.3316C20.831 11.9176 21.167 11.5816 21.581 11.5816C21.995 11.5816 22.331 11.9176 22.331 12.3316V16.3596C22.331 19.9536 20.018 22.3686 16.577 22.3686Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.86737 15.4282H11.8444C12.2244 15.4282 12.5804 15.2812 12.8494 15.0122L20.3584 7.50324C20.6664 7.19524 20.8364 6.78524 20.8364 6.34924C20.8364 5.91224 20.6664 5.50124 20.3584 5.19324L19.1414 3.97624C18.5044 3.34124 17.4684 3.34124 16.8304 3.97624L9.35737 11.4492C9.09837 11.7082 8.95137 12.0522 8.94237 12.4172L8.86737 15.4282ZM11.8444 16.9282H8.09837C7.89637 16.9282 7.70237 16.8462 7.56137 16.7012C7.42037 16.5572 7.34337 16.3622 7.34837 16.1592L7.44237 12.3802C7.46137 11.6282 7.76437 10.9212 8.29637 10.3882H8.29737L15.7704 2.91524C16.9924 1.69524 18.9794 1.69524 20.2014 2.91524L21.4184 4.13224C22.0114 4.72424 22.3374 5.51124 22.3364 6.34924C22.3364 7.18724 22.0104 7.97324 21.4184 8.56424L13.9094 16.0732C13.3584 16.6242 12.6244 16.9282 11.8444 16.9282Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7308 9.9166C19.5388 9.9166 19.3468 9.8436 19.2008 9.6966L14.6348 5.1306C14.3418 4.8376 14.3418 4.3626 14.6348 4.0696C14.9278 3.7766 15.4018 3.7766 15.6948 4.0696L20.2608 8.6366C20.5538 8.9296 20.5538 9.4036 20.2608 9.6966C20.1148 9.8436 19.9228 9.9166 19.7308 9.9166Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'EditIcon',
};
</script>
