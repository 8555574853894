<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5506 5.63525H9.5701C8.28544 5.63525 7.24316 6.67753 7.24316 7.96219V19.6726C7.24316 20.9543 8.28544 21.9996 9.5701 21.9996H18.5537C19.8353 21.9996 20.8806 20.9573 20.8806 19.6726V7.96219C20.8776 6.67753 19.8353 5.63525 18.5506 5.63525V5.63525ZM19.6656 19.6696C19.6656 20.2847 19.1657 20.7846 18.5506 20.7846H9.5701C8.95504 20.7846 8.45511 20.2847 8.45511 19.6696V7.96219C8.45511 7.34713 8.95504 6.8472 9.5701 6.8472H18.5537C19.1687 6.8472 19.6686 7.34713 19.6686 7.96219V19.6696H19.6656Z"
    />
    <path
      d="M5.32694 17.1493C4.71187 17.1493 4.21195 16.6494 4.21195 16.0343V4.32694C4.21195 3.71187 4.71187 3.21195 5.32694 3.21195H14.3105C14.9256 3.21195 15.4255 3.71187 15.4255 4.32694C15.4255 4.66022 15.6982 4.93291 16.0315 4.93291C16.3647 4.93291 16.6374 4.66022 16.6374 4.32694C16.6374 3.0453 15.5952 2 14.3105 2H5.32694C4.04227 2 3 3.04227 3 4.32694V16.0374C3 17.319 4.04227 18.3643 5.32694 18.3643C5.66022 18.3643 5.93291 18.0916 5.93291 17.7583C5.93291 17.4251 5.66022 17.1493 5.32694 17.1493V17.1493Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CopyIcon',
};
</script>
