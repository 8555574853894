<script>
export default {
  name: 'AppBreadcrumbs',
  data() {
    return {
      breadcrumbsPaths: [],
      existingPaths: [
        {
          name: 'Passes',
          path: 'requests',
          subpath: '/requests'
        },
        {
          name: 'The objects of the visit',
          path: 'objects',
          subpath: '/requests/objects'
        },
        {
          name: 'Adding a pass',
          path: 'add-pass',
          subpath: '/requests/objects/add-pass/:object_id'
        },
        {
          name: 'Editing a pass',
          path: 'edit-pass',
          subpath: '/requests/edit-pass/:id_pass'
        },
      ],
    }
  },
  mounted() {
    const url = this.$route.path;
    const urlPaths = url.split('/');

    this.existingPaths.forEach((pathItem) => {
      for (const urlPath of urlPaths) {
        if (pathItem.path === urlPath) {
          this.breadcrumbsPaths.push(pathItem);
          return;
        }
      }
    });
  },
}
</script>

<template>
  <div class="breadcrubms">
    <ul v-if="breadcrumbsPaths.length" class="breadcrubms__list">
      <router-link
        :to="existingPath.subpath"
        v-for="existingPath in breadcrumbsPaths"
        :key="existingPath.path"
        tag="li"
        class="breadcrubms__item"
      >
      {{ $t(existingPath.name) }}
      </router-link>
    </ul>
  </div>
</template>

<style lang="scss">
.breadcrubms {
  & &__list {
    display: flex;
    gap: 6px;
    padding-left: 0;
    margin-bottom: 12px;
  }

  &__item {
    position: relative;
    font-size: 12px;
    line-height: 16px;
    list-style-type: none;

    &:not(:last-child)::after {
      padding-left: 2px;
      content: '-';
    }

    &:not(:last-child) {
      color: $text-secondary;
    }

    &:last-child {
      pointer-events: none;
    }
  }
}
</style>
