import axios from 'axios';

export const getAll = (params) => axios({
  url: '/zones',
  method: 'GET',
  params,
});
export const create = (zoneFormData) => axios({
  url: '/zones',
  method: 'POST',
  data: zoneFormData,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const update = (id, zoneFormData) => {
  // обход проблем с методом PUT в Laravel
  zoneFormData.append('_method', 'PUT');
  return axios({
    url: `/zones/${id}`,
    method: 'POST',
    data: zoneFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const remove = (id) => axios({
  url: `/zones/${id}`,
  method: 'DELETE',
});

export const getDevices = (id) => axios({
  url: `/zones/${id}/devices`,
  method: 'GET',
});

export const changeDevice = ({ id, device_id, is_bind }) => axios({
  url: `/zones/${id}/bind-device`,
  method: 'PATCH',
  data: { device_id, is_bind },
});

export const getObjects = (id) => axios({
  url: `/zones/${id}`,
  method: 'GET',
});
