<template>
  <svg class="srp-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.247 22c-1.355 0-2.676-.015-3.983-.042-1.672-.033-2.83-1.117-3.018-2.829-.315-2.84-.854-9.534-.86-9.601a.75.75 0 01.688-.808c.407-.011.775.275.808.687.005.068.543 6.739.855 9.557.107.973.632 1.475 1.558 1.494 2.5.053 5.05.056 7.8.006.985-.019 1.517-.511 1.627-1.507.31-2.794.85-9.482.856-9.55.033-.412.398-.7.807-.687a.751.751 0 01.688.808c-.006.068-.548 6.779-.86 9.594-.194 1.747-1.348 2.81-3.09 2.842-1.333.023-2.62.036-3.876.036zM20.708 6.99H3.75a.75.75 0 010-1.5h16.958a.75.75 0 010 1.5z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.44 6.99a2.405 2.405 0 01-2.35-1.928l-.243-1.216c-.05-.185-.262-.346-.502-.346h-4.233a.53.53 0 00-.512.392l-.233 1.17a2.404 2.404 0 01-2.35 1.927.75.75 0 010-1.5.901.901 0 00.88-.722l.243-1.216c.247-.932 1.054-1.55 1.972-1.55h4.233c.918 0 1.725.618 1.962 1.505l.254 1.261a.899.899 0 00.88.722.75.75 0 010 1.5z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DeleteIcon',
};
</script>
