<script>
import { mapGetters, mapActions } from 'vuex';
import { REQUEST_TYPES } from '@/constants/index';
import AppBreadcrumbs from '@/components/blocks/AppBreadcrumbs.vue';

export default {
  components: { AppBreadcrumbs },
  name: 'ObjectsToVisit',
  computed: {
    ...mapGetters(['getObjectsToVisit', 'getLocalObjectsToVisit']),
  },
  methods: {
    ...mapActions(['fetchObjectsToVisit']),
    clickItemPass(pass) {
      this.$router.push({
        path: `/requests/objects/add-pass/${pass.ID}`,
      });
    },
  },
  mounted() {
    if (!this.getObjectsToVisit.length) {
      this.fetchObjectsToVisit();
    }
  },
};
</script>

<template>
  <div class="container">
    <AppBreadcrumbs/>
    <div class="text-h4 mb-4">
      {{ $t('Select an object to visit') }}
    </div>
    <div
      v-for="item in getLocalObjectsToVisit"
      :key="item.ID"
      class="objects-to-visit__pass-item text-h4 mb-4 pt-2 pb-2 pl-3 pr-3"
      @click="clickItemPass(item)"
    >
      {{ item.NAME }}
    </div>
  </div>
</template>

<style lang="scss">
.objects-to-visit {
  &__pass-item {
    background-color: $background-secondary;
    border-radius: 4px;
  }
}
</style>
