import { ZONE_STATUSES } from '@/constants';

export const tableHeaders = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    width: '80px',
  },
  {
    text: 'Name',
    value: 'NAME',
    cellClass: 'font-weight-medium',
  },
  {
    text: 'Status',
    value: 'STATUS',
    filterable: false,
    width: '150px',
  },
  {
    text: 'Date and time of change',
    value: 'DATE_EDIT',
    filterable: false,
    width: '150px',
  },
  {
    text: 'The user who made the change',
    value: 'USER_ID',
    filterable: false,
    sortable: false,
    width: '190px',
  },
  {
    text: 'Description',
    value: 'DESCRIPTION',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    sortable: false,
    filterable: false,
  },
];

export const filterModel = [
  {
    name: 'STATUS',
    type: 'select',
    data: null,
    items: ZONE_STATUSES,
    label: 'Status',
  },
  {
    name: 'DATE_S',
    type: 'date-time-picker',
    data: null,
    label: 'Date and time changed from',
  },
  {
    name: 'DATE_PO',
    type: 'date-time-picker',
    data: null,
    label: 'Date and time changed to',
  },
  {
    name: 'USER',
    type: 'autocomplete',
    data: null,
    items: [],
    label: 'The user who made the change',
  },
];
