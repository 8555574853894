import axios from 'axios';

export const getAll = () => axios({
  url: '/config',
  method: 'GET',
});

export const getAllByGroup = () => axios({
  url: '/config/data-section',
  method: 'GET',
});

export const update = (data) => axios({
  url: '/config',
  method: 'PUT',
  data: { SIDS: data },
});

export const getFileLinks = () => axios({
  url: '/config/file-links',
  method: 'GET',
});

export const downloadFile = () => axios({
  url: '/config/download-file',
  method: 'GET',
});

export const uploadFile = (fileFormData) => axios({
  url: '/config/upload-file',
  method: 'POST',
  data: fileFormData,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const deleteFile = (type) => axios({
  url: '/config/file',
  method: 'DELETE',
  data: {
    type,
  },
});
