<template>
  <svg
    class="srp-icon"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 14A7 7 0 107 0a7 7 0 000 14z"
        fill="#B3BBBF"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.625 4.492a.565.565 0 00-.798 0L6.964 6.354 5.138 4.528a.565.565 0 00-.799.798l1.827 1.827-1.863 1.863a.565.565 0 10.799.798l1.862-1.862 1.827 1.826a.565.565 0 00.799-.798L7.763 7.153 9.625 5.29a.565.565 0 000-.799z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseCircle',
};
</script>
