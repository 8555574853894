<template>
  <svg class="srp-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_4442_202025"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="21"
      height="21"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 2H22.4996V22.4995H2V2Z" fill="white" />
    </mask>
    <g mask="url(#mask0_4442_202025)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.35908 5.06067C4.36108 5.06067 4.31108 5.11266 4.24808 5.17466C4.00608 5.40966 3.50508 5.89866 3.50008 6.92167C3.49208 8.35266 4.43308 11.0097 8.96408 15.5397C13.4741 20.0477 16.1271 20.9997 17.5611 20.9997H17.5821C18.6051 20.9947 19.0931 20.4927 19.3291 20.2517C19.4011 20.1777 19.4591 20.1237 19.5001 20.0897C20.4961 19.0877 21.0061 18.3417 21.0011 17.8637C20.9941 17.3757 20.3871 16.7987 19.5481 16.0007C19.2811 15.7467 18.9911 15.4707 18.6851 15.1647C17.8921 14.3737 17.4991 14.5087 16.6351 14.8127C15.4401 15.2317 13.8001 15.8017 11.2521 13.2527C8.70008 10.7027 9.27108 9.06466 9.68908 7.86967C9.99108 7.00566 10.1291 6.61167 9.33508 5.81767C9.02408 5.50767 8.74508 5.21367 8.48808 4.94367C7.69508 4.10967 7.12308 3.50666 6.63808 3.49967H6.63008C6.15108 3.49967 5.40708 4.01167 4.35408 5.06467C4.35708 5.06167 4.35808 5.06067 4.35908 5.06067ZM17.5621 22.4997C15.0701 22.4997 11.8211 20.5157 7.90408 16.6007C3.97208 12.6697 1.98508 9.41067 2.00008 6.91367C2.00908 5.26367 2.87408 4.41866 3.19908 4.10167C3.21608 4.08067 3.27408 4.02366 3.29408 4.00366C4.72808 2.56867 5.69508 1.98867 6.65708 1.99967C7.77408 2.01467 8.56908 2.85067 9.57508 3.90967C9.82408 4.17167 10.0941 4.45667 10.3951 4.75666C11.8551 6.21667 11.4391 7.40767 11.1051 8.36367C10.7411 9.40667 10.4261 10.3067 12.3121 12.1927C14.2001 14.0787 15.1001 13.7637 16.1391 13.3967C17.0961 13.0627 18.2841 12.6447 19.7461 14.1047C20.0421 14.4007 20.3231 14.6677 20.5821 14.9147C21.6461 15.9257 22.4861 16.7247 22.5001 17.8447C22.5121 18.7997 21.9321 19.7727 20.5001 21.2057L19.8661 20.7757L20.4021 21.2997C20.0851 21.6247 19.2411 22.4907 17.5901 22.4997H17.5621Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TelephoneIcon',
};
</script>
