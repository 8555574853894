<template>
  <svg class="srp-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9228 10.1998C16.5088 10.1998 16.1728 9.86377 16.1728 9.44977V7.30277C16.1728 5.20677 14.4678 3.50177 12.3718 3.50177H12.3558C11.3428 3.50177 10.3938 3.89177 9.67676 4.60277C8.95476 5.31677 8.55576 6.26977 8.55176 7.28577V9.44977C8.55176 9.86377 8.21576 10.1998 7.80176 10.1998C7.38776 10.1998 7.05176 9.86377 7.05176 9.44977V7.30277C7.05776 5.86277 7.61476 4.53377 8.61976 3.53777C9.62576 2.54077 10.9538 1.96377 12.3748 2.00177C15.2948 2.00177 17.6728 4.37977 17.6728 7.30277V9.44977C17.6728 9.86377 17.3368 10.1998 16.9228 10.1998Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.542 10.1284C6.864 10.1284 5.5 11.4924 5.5 13.1704V17.4594C5.5 19.1374 6.864 20.5014 8.542 20.5014H16.183C17.86 20.5014 19.225 19.1374 19.225 17.4594V13.1704C19.225 11.4924 17.86 10.1284 16.183 10.1284H8.542ZM16.183 22.0014H8.542C6.037 22.0014 4 19.9644 4 17.4594V13.1704C4 10.6654 6.037 8.62842 8.542 8.62842H16.183C18.688 8.62842 20.725 10.6654 20.725 13.1704V17.4594C20.725 19.9644 18.688 22.0014 16.183 22.0014Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3623 17.1756C11.9483 17.1756 11.6123 16.8396 11.6123 16.4256V14.2046C11.6123 13.7906 11.9483 13.4546 12.3623 13.4546C12.7763 13.4546 13.1123 13.7906 13.1123 14.2046V16.4256C13.1123 16.8396 12.7763 17.1756 12.3623 17.1756Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LockIcon',
};
</script>
