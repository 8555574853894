import api from '@/api/index.js';
import { OBJECT_SYSTEM_ID, OBJECT_STATUSES } from '@/constants/index.js';

const isActiveObject = (object) => {
  const [activeObjectData] = OBJECT_STATUSES.filter((v) => v.text === 'Active');
  return activeObjectData.value === object.STATUS;
};

export default {
  state: {
    objectsToVisit: [],
    activeObjectsToVisit: {},
  },
  getters: {
    getObjectsToVisit(state) {
      return state.objectsToVisit;
    },
    getLocalObjectsToVisit(state) {
      return state.objectsToVisit.filter(object => object.SYSTEM_ID === OBJECT_SYSTEM_ID.local && isActiveObject(object));
    },
    getActiveObjectsToVisit(state) {
      return state.activeObjectsToVisit;
    },
  },
  mutations: {
    setObjectsToVisit(state, objects) {
      state.objectsToVisit = objects;
    },
    setActiveObjectsToVisit(state, number) {
      state.activeObjectsToVisit = state.objectsToVisit.find(object => object.ID === +number)
    },
  },
  actions: {
    async fetchObjectsToVisit({ commit }) {
      try {
        const response = await api.objects.getAll();
        const allObjects = response.data.DATA;
        commit('setObjectsToVisit', allObjects);
      } catch (error) {
        console.error('Error fetching objects to visit:', error);
      }
    },
    updateActiveObject({ commit }, payload) {
      commit('setActiveObjectsToVisit', payload);
    },
  },
};
