<template>
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.84112 2.95339C5.05078 2.77368 5.36643 2.79796 5.54614 3.00762L9.54614 7.67429C9.70664 7.86153 9.70664 8.13783 9.54614 8.32508L5.54614 12.9917C5.36643 13.2014 5.05078 13.2257 4.84112 13.046C4.63145 12.8663 4.60717 12.5506 4.78688 12.341L8.50797 7.99968L4.78688 3.65841C4.60717 3.44875 4.63145 3.1331 4.84112 2.95339ZM7.50791 2.95346C7.71758 2.77375 8.03323 2.79803 8.21294 3.00769L12.2129 7.67436C12.3734 7.8616 12.3734 8.1379 12.2129 8.32515L8.21294 12.9918C8.03323 13.2015 7.71758 13.2258 7.50791 13.046C7.29825 12.8663 7.27397 12.5507 7.45368 12.341L11.1748 7.99975L7.45368 3.65848C7.27397 3.44882 7.29825 3.13317 7.50791 2.95346Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'DoubleAltArrowIcon',
};
</script>
