<template>
  <div>
    <v-tabs v-model="tabsModel" class="srp-tabs d-flex flex-column" @change="changeTab">
      <v-row class="mb-5">
        <v-col cols="auto" align-self="center">
          <h2 class="text-h2 text--primary">{{ $t("Reference books") }}</h2>
        </v-col>
        <v-tab :href="`#cars`">{{ $t("Cars") }}</v-tab>
        <v-tab v-if="$store.getters.user.PERMISSION?.is_superadmin" :href="`#white-list`">{{ $t("White list") }}</v-tab>
        <v-tab :href="`#black-list`">{{ $t("Black list") }}</v-tab>
        <v-tab  v-if="$store.getters.user.PERMISSION?.is_superadmin" :href="`#emergency-services`">{{ $t("Emergency services") }}</v-tab>
        <v-col v-if="tabsModel !== 'emergency-services'" cols="auto" class="d-flex flex-grow-1 justify-end">
          <v-btn depressed color="primary" @click="add">
            <v-icon size="18" class="mr-2">$plus</v-icon>
            {{ tabsModel === 'cars' ? $t("Add сar brand") : $t("Add") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-tab-item :value="`cars`">
        <v-expansion-panels class="expansion-panels-striped" accordion multiple flat active-class="cars__expansion-panel_active">
          <v-expansion-panel
            v-for="brand in carsGroupedByBrand['null']"
            :key="brand.ID"
          >
            <v-expansion-panel-header class="cars__expansion-panel-header">
              <div class="d-flex justify-space-between align-center">
                {{ brand.NAME }}
                <div class="d-flex align-center">
                  <v-tooltip bottom color="primary" v-if="$store.getters.user.PERMISSION?.is_superadmin">
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mr-4"
                        color="primary"
                        v-on="on"
                        @click.stop="addCarModel(brand)"
                      >
                        $addSquareIcon
                      </v-icon>
                    </template>
                    {{ $t("Add car model") }}
                  </v-tooltip>
                  <v-icon
                    class="mr-4"
                    color="red"
                    @click.stop="showRemoveRequestConfirm({ type: 'brand', ...brand})"
                  >
                    $delete
                  </v-icon>
                </div>
              </div>
              <template v-slot:actions>
                <v-icon color="primary">
                  $down
                </v-icon>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="cars__expansion-panel-content">
              <ul class="cars__list">
                <li v-for="model in carsGroupedByBrand[brand.ID]" :key="model.ID" class="cars__list-item">
                  <span class="pl-4">
                    {{ model.NAME }}
                  </span>
                  <v-icon
                    class="mr-4"
                    color="red"
                    @click.stop="showRemoveRequestConfirm({ ID: model.ID, type: 'model', NAME: model.NAME })"
                  >
                    $delete
                  </v-icon>
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>

      <v-tab-item :value="`white-list`">
        <v-data-table
          :headers="translatedTableHeaders"
          :items="tabsModel === 'white-list' ? whiteList : blackList"
          sort-by="ID"
          class="table-striped"
          :sort-desc="true"
          :items-per-page="-1"
          :loading="tableLoading"
          :header-props="{ 'sort-icon': '$swap' }"
          :loading-text="`${$t('Loading')}...`"
          :no-data-text="$t('Data not found')"
          :no-results-text="$t('Data not found')"
          :footer-props="{
            'items-per-page-text': $t('Lines per page'),
            'items-per-page-options': [15, 25, 50, 100],
          }"
        >
          <template #[`item.ACTIONS`]="{ item }">
            <div class="d-inline-flex">
              <v-icon color="red" @click="showRemoveRequestConfirm(item)">
                $delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item :value="`black-list`">
        <v-data-table
          :headers="translatedTableHeaders"
          :items="blackList"
          sort-by="ID"
          class="table-striped"
          :sort-desc="true"
          :items-per-page="-1"
          :loading="tableLoading"
          :header-props="{ 'sort-icon': '$swap' }"
          :loading-text="`${$t('Loading')}...`"
          :no-data-text="$t('Data not found')"
          :no-results-text="$t('Data not found')"
          :footer-props="{
            'items-per-page-text': $t('Lines per page'),
            'items-per-page-options': [15, 25, 50, 100],
          }"
        >
          <template #[`item.ACTIONS`]="{ item }">
            <div class="d-inline-flex">
              <v-icon color="red" @click="showRemoveRequestConfirm(item)">
                $delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item :value="`emergency-services`">
        <v-data-table
          :headers="translatedEmergencyHeaders"
          :items="emergency"
          sort-by="ID"
          class="table-striped"
          :sort-desc="true"
          :items-per-page="-1"
          :loading="tableLoading"
          :header-props="{ 'sort-icon': '$swap' }"
          :loading-text="`${$t('Loading')}...`"
          :no-data-text="$t('Data not found')"
          :no-results-text="$t('Data not found')"
          :footer-props="{
            'items-per-page-text': $t('Lines per page'),
            'items-per-page-options': [15, 25, 50, 100],
          }"
        >
          <template #[`item.FREE_ENTRANCE`]="{ item }">
            <v-checkbox
              v-model="item.FREE_ENTRANCE"
              @change="changeEmergencyStatus(item)"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>

    <BrandOrModelDialog
      :isShow="isShow"
      :type="dialogType"
      :car-brand="currentCarBrand"
      :car-brand-id="currentCarBrandId"
      @close-dialog="isShow = false"
      @add-car-model-or-brand="addCarModelOrBrand"
    />

    <CarDialog
      :isShow="isAddCarDialogActive"
      :access-type-number="accessTypeNumber"
      :cars-list="carsGroupedByBrand"
      @close-dialog="isAddCarDialogActive = false"
      @add-car="addCar"
    />
    <confirm-remove-dialog
      v-if="requestRemoveConfirm"
      @closeDialog="requestRemoveConfirm = false"
      @confirm="chooseMethodDelete"
    >
      <template #title>
        <div v-html="outputTextTitle"></div>
      </template>
      <template #default>
        {{ $t("After deletion, it will be impossible to restore the car's data.") }}
      </template>
    </confirm-remove-dialog>
  </div>
</template>

<script>
import api from '@/api';
import { groupBy } from 'lodash-es';
import BrandOrModelDialog from './brandOrModelDialog.vue';
import { tableHeaders, emergencyHeaders } from './data.referenceBooks';
import CarDialog from './carDialog.vue';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import ConfirmRemoveDialog from '@/components/blocks/ConfirmRemoveDialog.vue';
import { ACCESS_TYPE } from '@/constants/index'

export default {
  name: 'ReferenceBooks',
  components: { BrandOrModelDialog, CarDialog, ConfirmRemoveDialog },
  data() {
    return {
      tabsModel: 'cars',
      tableHeaders,
      emergencyHeaders,
      tableLoading: false,

      cars: [],

      activeItemDelete: null,
      requestRemoveConfirm: false,

      // CarDialog
      isShow: false,
      dialogType: 'add-model',
      currentCarBrand: '',
      currentCarBrandId: null,

      // addCarDialog
      isAddCarDialogActive: false,

      whiteList: [],
      blackList: [],
      accessTypeNumber: 0,

      emergency: []
    }
  },
  computed: {
    carsGroupedByBrand() {
      return groupBy(this.cars, 'BRAND_ID');
    },
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
    translatedEmergencyHeaders() {
      return this.emergencyHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
    outputTextTitle() {
      if (this.tabsModel === "cars") {
        return `${this.$t("Delete a car")} ${this.activeItemDelete.NAME}`;
      }
      if (this.tabsModel === "white-list" || this.tabsModel === "black-list") {
        return `${this.$t("Delete a car")} ${this.activeItemDelete.CAR_NUM}`;
      }
    }
  },
  mounted() {
    this.getCars();
  },
  methods: {
    async getCarAccess(accessTypeNumber) {
      const response = await api.referenceBooks.getCarAccess(accessTypeNumber);
      return response.data.DATA;
    },
    async getEmergencyServices() {
      const response = await api.referenceBooks.getEmergency();
      return response.data.DATA;
    },
    async changeEmergencyStatus(emergency) {
      try {
        await api.referenceBooks.changeEmergencyStatus(emergency);
      } catch (error) {
        emergency.FREE_ENTRANCE = !emergency.FREE_ENTRANCE;
        this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
      }
    },
    async changeTab() {
      const tabActions = async (tabsModel, text, tabs) => {
        if (this.tabsModel === tabsModel) {
          this.accessTypeNumber = (ACCESS_TYPE.find((type) => type.text === text)).value;
          if (!this[tabs].length) {
            this[tabs] = await this.getCarAccess(this.accessTypeNumber);
          }
        }
      }
      tabActions('white-list', 'Allowed', 'whiteList');
      tabActions('black-list', 'Banned', 'blackList');

      if (this.tabsModel === 'emergency-services' && !this.emergency.length) {
        this.emergency = await this.getEmergencyServices();
      }
    },
    showRemoveRequestConfirm(brandOrModel) {
      this.activeItemDelete = brandOrModel;
      this.requestRemoveConfirm = true;
    },
    async removeBrandOrModel() {
      try {
        await api.referenceBooks.deleteModelOrBrand(this.activeItemDelete);
        const currentBrandOrModelIndex = this.cars.findIndex((currentCar) => {
          return currentCar.ID === this.activeItemDelete.ID;
        });
        this.cars.splice(currentBrandOrModelIndex, 1);
      } catch (error) {
        this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
      } finally {
        this.requestRemoveConfirm = false;
      }
    },
    add() {
      if (this.tabsModel === 'cars') {
        this.addCarBrand();
      }

      if (this.tabsModel === 'white-list' || this.tabsModel === 'black-list') {
        this.isAddCarDialogActive = true;
      }
    },
    // cars
    async getCars() {
      const response = await api.referenceBooks.getAllCars();
      this.cars = response.data.DATA;
    },
    async addCarModel(brand) {
      this.currentCarBrand = brand.NAME;
      this.currentCarBrandId = brand.ID;
      this.dialogType = 'add-model';
      this.isShow = true;
    },
    async addCarBrand() {
      this.dialogType = 'add-brand';
      this.isShow = true;
    },
    addCarModelOrBrand(car) {
      this.cars.push(car);
    },

    // black and white list
    addCar(car) {
      if (this.tabsModel === 'white-list') {
        this.whiteList.push(car);
      }

      if (this.tabsModel === 'black-list') {
        this.blackList.push(car);
      }
    },
    async removeCar() {
      try {
        await api.referenceBooks.deleteCarAccess(this.activeItemDelete.ID);
        const currentList = this.tabsModel === 'white-list' ? this.whiteList : this.blackList;

        const currentCarIndex = currentList.findIndex((currentCar) => {
          return currentCar.ID === this.activeItemDelete.ID;
        });
        currentList.splice(currentCarIndex, 1);
      } catch (error) {
        this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
      } finally {
        this.requestRemoveConfirm = false;
      }
    },
    chooseMethodDelete() {
      if (this.tabsModel === 'cars') {
        this.removeBrandOrModel()
        return
      }
      this.removeCar();
    },
  }
}
</script>
