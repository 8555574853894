import { SYSTEM_STATUSES } from '@/constants';

export const tableHeaders = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    width: '80px',
  },
  {
    text: 'Name',
    value: 'NAME',
    cellClass: 'font-weight-medium',
  },
  {
    text: 'Status',
    value: 'STATUS',
    filterable: false,
    width: '150px',
  },
  {
    text: 'Key',
    value: 'KEY',
    sortable: false,
    width: '250px',
  },
  {
    text: 'User',
    value: 'USER_ID',
    filterable: false,
    sortable: false,
  },
  {
    text: 'Description',
    value: 'DESCRIPTION',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    filterable: false,
    sortable: false,
    width: '140px',
  },
];

export const filterModel = [
  {
    name: 'STATUS',
    type: 'select',
    data: null,
    items: SYSTEM_STATUSES,
    label: 'Status',
  },
  {
    name: 'USER',
    type: 'autocomplete',
    data: null,
    items: [],
    label: 'User',
  },
];
