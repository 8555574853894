export default {
  methods: {
    updateStateMixin(entity, value, state = null, config = null) {
      if (config === 'system') {
        this.$store.commit('updateConfigItem', { sid: entity, value });
        return;
      }
      state[entity] = value;
    },
  },
};
