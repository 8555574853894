import {
  DEVICE_STATUSES,
  DEVICE_TYPE,
  DEVICE_IS_EXTERNAL,
  DEVICE_IS_ENTRY_out,
  DEVICE_NO_PASS_EXIT_out,
  DEVICE_EXPIRED_EXIT_out,
  DEVICE_EXPIRED_OUT_out,
} from '@/constants';

export const tableHeaders = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    width: '80px',
  },
  {
    text: 'Name',
    value: 'NAME',
    cellClass: 'font-weight-medium',
    width: '180px',
  },
  {
    text: 'Status',
    value: 'STATUS',
    filterable: false,
    width: '150px',
  },
  {
    text: 'Device type',
    value: 'TYPE',
    filterable: false,
    sortable: false,
    width: '180px',
  },
  {
    text: 'Confirmation of entry',
    value: 'CONFIRM_ENTRANCE',
    filterable: false,
    sortable: false,
    width: '140px',
  },
  {
    text: 'Confirmation of departure',
    value: 'CONFIRM_EXIT',
    filterable: false,
    sortable: false,
    width: '140px',
  },
  {
    text: 'Gate',
    value: 'GATE_ID',
    filterable: false,
    sortable: false,
    width: '180px',
  },
  {
    text: 'SID',
    value: 'SID',
    filterable: false,
    sortable: false,
    width: '180px',
  },
  {
    text: 'Address',
    value: 'ADDRESS',
    sortable: false,
    width: '180px',
  },
  {
    text: 'Outer perimeter',
    value: 'IS_EXTERNAL',
    filterable: false,
    sortable: false,
    width: '180px',
  },
  {
    text: 'Direction of passage',
    value: 'IS_ENTRY',
    filterable: false,
    sortable: false,
    width: '190px',
  },
  {
    text: 'Configuration',
    value: 'CONFIGURATION',
    filterable: false,
    sortable: false,
    width: '180px',
  },
  {
    text: 'Date and time of change',
    value: 'DATE_EDIT',
    filterable: false,
    width: '150px',
  },
  {
    text: 'The user who made the change',
    value: 'USER_ID',
    filterable: false,
    sortable: false,
    width: '250px',
  },
  {
    text: 'Let exit who did not enter from the outer perimeter',
    value: 'NO_PASS_EXIT',
    filterable: false,
    sortable: false,
    width: '180px',
  },
  {
    text: 'Let exit expired passes from the outer perimeter',
    value: 'EXPIRED_EXIT',
    filterable: false,
    sortable: false,
    width: '250px',
  },
  {
    text: 'Let exit expired passes from the inner perimeter',
    value: 'EXPIRED_OUT',
    filterable: false,
    sortable: false,
    width: '250px',
  },
  {
    text: 'Description',
    value: 'DESCRIPTION',
    sortable: false,
    width: '180px',
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    class: 'fixed-right-cel',
    cellClass: 'fixed-right-cel',
    filterable: false,
    sortable: false,
  },
];

export const externalReadersHeaders = [
  {
    text: 'Name',
    value: 'READER_NAME',
    width: '200px',
    filterable: false,
    sortable: false,
  },
  {
    text: 'Direction',
    value: 'IS_ENTRY',
    width: '150px',
    filterable: false,
    sortable: false,
  },
  {
    text: 'Type',
    value: 'READER_TYPE',
    width: '200px',
    filterable: false,
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    filterable: false,
    sortable: false,
  },
]

export const addExternalReadersHeaders = [
  {
    text: 'Name',
    value: 'READER_NAME',
    width: '200px',
    filterable: true,
    sortable: false,
  },
  {
    text: 'Direction',
    value: 'IS_ENTRY',
    align: 'end',
    filterable: false,
    sortable: false,
  }
]

export const filterModel = [
  {
    name: 'DEVICE_STATUS',
    type: 'select',
    data: [],
    items: DEVICE_STATUSES,
    label: 'Status',
  },

  {
    name: 'DEVICE_TYPE',
    type: 'select',
    data: [],
    items: DEVICE_TYPE,
    label: 'Device type',
  },
  {
    name: 'DEVICE_IS_EXTERNAL',
    type: 'select',
    data: [],
    items: DEVICE_IS_EXTERNAL,
    label: 'Outer perimeter',
  },
  {
    name: 'DEVICE_NO_PASS_EXIT',
    type: 'select',
    data: [],
    items: DEVICE_NO_PASS_EXIT_out,
    label: 'Let exit who did not enter from the outer perimeter',
  },
  {
    name: 'DEVICE_EXPIRED_EXIT',
    type: 'select',
    data: [],
    items: DEVICE_EXPIRED_EXIT_out,
    label: 'Let exit expired passes from the outer perimeter',
  },
  {
    name: 'DEVICE_EXPIRED_OUT',
    type: 'select',
    data: [],
    items: DEVICE_EXPIRED_OUT_out,
    label: 'Let exit expired passes from the inner perimeter',
  },
];
