<template>
  <svg class="srp-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_1521_11790"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="8"
      width="20"
      height="15"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 8.79395H21.9996V22.5379H2V8.79395Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1521_11790)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.565 22.5379H6.435C3.99 22.5379 2 20.5489 2 18.1029V13.2279C2 10.7829 3.99 8.79395 6.435 8.79395H7.368C7.782 8.79395 8.118 9.12995 8.118 9.54395C8.118 9.95795 7.782 10.2939 7.368 10.2939H6.435C4.816 10.2939 3.5 11.6099 3.5 13.2279V18.1029C3.5 19.7219 4.816 21.0379 6.435 21.0379H17.565C19.183 21.0379 20.5 19.7219 20.5 18.1029V13.2189C20.5 11.6059 19.188 10.2939 17.576 10.2939H16.633C16.219 10.2939 15.883 9.95795 15.883 9.54395C15.883 9.12995 16.219 8.79395 16.633 8.79395H17.576C20.015 8.79395 22 10.7789 22 13.2189V18.1029C22 20.5489 20.01 22.5379 17.565 22.5379Z"
        fill="#0060AE"
      />
    </g>
    <mask
      id="mask1_1521_11790"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="11"
      y="2"
      width="2"
      height="14"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.25 2H12.75V15.5409H11.25V2Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_1521_11790)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 15.541C11.586 15.541 11.25 15.205 11.25 14.791V2.75C11.25 2.336 11.586 2 12 2C12.414 2 12.75 2.336 12.75 2.75V14.791C12.75 15.205 12.414 15.541 12 15.541Z"
        fill="#0060AE"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.08471 6.42853C8.89371 6.42853 8.70171 6.35553 8.55571 6.20953C8.26271 5.91753 8.26071 5.44353 8.55371 5.14953L11.4687 2.22153C11.7497 1.93853 12.2497 1.93853 12.5307 2.22153L15.4467 5.14953C15.7387 5.44353 15.7377 5.91753 15.4447 6.20953C15.1507 6.50153 14.6767 6.50153 14.3847 6.20753L11.9997 3.81353L9.61571 6.20753C9.46971 6.35553 9.27671 6.42853 9.08471 6.42853Z"
      fill="#0060AE"
    />
  </svg>
</template>

<script>
export default {
  name: 'PaperUpload',
};
</script>
