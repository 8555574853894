<template>
  <svg class="srp-icon srp-stroke-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0007 22V21.543L12 22C6.48595 22 2 17.5139 2 11.9998C2 6.48595 6.48595 2 12 2C17.5141 2 22 6.48595 22 11.9998C22 17.5139 17.5142 22 12.0007 22ZM12 2.91405C6.98993 2.91405 2.91405 6.98997 2.91405 11.9998C2.91405 17.0099 6.98993 21.0859 12 21.0859H12.0007C17.0102 21.0859 21.0859 17.0098 21.0859 11.9998C21.0859 6.98993 17.0101 2.91405 12 2.91405Z"
      stroke-width="0.5"
    />
    <path
      d="M12.0005 14.105C11.748 14.105 11.5435 13.9005 11.5435 13.648V11.7135C11.5435 11.4611 11.748 11.2565 12.0005 11.2565C13.012 11.2565 13.8348 10.4337 13.8348 9.42223C13.8348 8.41056 13.012 7.58739 12.0005 7.58739C10.989 7.58739 10.166 8.41056 10.166 9.42223C10.166 9.67468 9.96143 9.87926 9.70898 9.87926C9.45653 9.87926 9.25195 9.67468 9.25195 9.42223C9.25195 7.90658 10.485 6.67334 12.0005 6.67334C13.516 6.67334 14.7488 7.90658 14.7488 9.42223C14.7488 10.782 13.7562 11.9143 12.4575 12.1326V13.648C12.4575 13.9005 12.253 14.105 12.0005 14.105Z"
      stroke-width="0.5"
    />
    <path
      d="M11.9997 16.9271C12.4153 16.9271 12.7523 16.5901 12.7523 16.1745C12.7523 15.7588 12.4153 15.4219 11.9997 15.4219C11.584 15.4219 11.2471 15.7588 11.2471 16.1745C11.2471 16.5901 11.584 16.9271 11.9997 16.9271Z"
      stroke-width="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: 'QuestionIcon',
};
</script>
