<script>
import api from '@/api/index.js';
import moment from 'moment';
import DateTimePicker from '@/components/blocks/DateTimePicker.vue';
import MultiPassRadioBtnNew from '@/components/blocks/MultiPassRadioBtnNew.vue';
import AppBreadcrumbs from '@/components/blocks/AppBreadcrumbs.vue';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';
import { mapGetters, mapActions } from 'vuex';
import {
  ALLOWED_PASS_TYPES,
  REQUEST_TYPES,
} from '@/constants/index.js';
import { getCurrentDateWithFixedTime } from '@/helpers/helpers.js';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import ConfirmRemoveDialog from '@/components/blocks/ConfirmRemoveDialog.vue';
import AppUiInfoBadge from '@/components/blocks/AppUiInfoBadge.vue';
import TimeRangePicker from '@/components/blocks/TimeRangePicker';

export default {
  name: 'DialogMobileRequests',
  components: {
    DateTimePicker,
    MultiPassRadioBtnNew,
    AppBreadcrumbs,
    ConfirmRemoveDialog,
    AppUiInfoBadge,
    TimeRangePicker,
  },
  data() {
    return {
      formValid: false,

      isEmptyDateBegin: false,
      isEmptyDateEnd: false,
      isEmptyDatePass: false,
      isEmptyStartTimePass: false,
      isEmptyEndTimePass: false,

      enumRequestState: [],
      requestForm: this.createRequestFormModel(),

      newDateVisit: {
        date: new Date(),
        startTime: getCurrentDateWithFixedTime({hours: 8}),
        endTime: getCurrentDateWithFixedTime({hours: 20}),
      },

      requestRemoveConfirm: false,

      carFormRules: {
        V_NAME: [(v) => !!v || this.$t('You must enter «Name of visitor»')],
        V_PHONE: [
          (v) =>
            (!!v && !!/^[\d\+][\d\(\)\ -]{4,14}\d$/.test(v)) ||
            this.$t('You must enter «Phone number»'),
        ],
        V_EMAIL: [
          (v) =>
            (!!v && !!/^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i.test(v)) ||
            this.$t('You must enter «Email»'),
        ],
        STATE: [(v) => !!v || v === 0 || this.$t('You must enter «Status»')],
        OBJECT_ID: [
          (v) => !!v || this.$t('You must select «Object of visit».'),
        ],
        CAR_BRAND: [(v) => !!v || `${this.$t('You must select «Car brand»')}.`],
        CAR_MODEL: [(v) => !!v || `${this.$t('You must select «Car model»')}.`],
        CAR_NUM: [
          (v) => !!v || `${this.$t('You need to fill in «License plate»')}.`,
        ],
        MULTI_PASS: [
          (v) => {
            if (
              this.getActiveObjectsToVisit.ALLOWED_PASS_TYPE === ALLOWED_PASS_TYPES.singleAndMultiPass
            ) {
              return true;
            }
            const isPassTypeSinglePass =
              this.getActiveObjectsToVisit.ALLOWED_PASS_TYPE === ALLOWED_PASS_TYPES.multiPass;
            if (this.requestForm.MULTI_PASS === isPassTypeSinglePass) {
              return true;
            }
            return `${this.$t('It is necessary to «Change the value»')}.`;
          },
        ],
      },
    }
  },
  async mounted() {
    this.$refs.requestForm.resetValidation();
    this.$store.commit(COMMON_SET_LOADING);
    const enumState = [];
    if (this.$store.getters.getEnums['ENUM.Request.STATE']?.length) {
      this.$store.getters.getEnums['ENUM.Request.STATE'].forEach((item) => {
        enumState.push({
          text: item.NAME,
          value: item.NUMBER,
        })
      })
      this.enumRequestState = enumState;
    }
    if (!this.carsBrands.length) await this.$store.dispatch('getCars');
    if (!this.getObjectsToVisit.length) {
      await this.fetchObjectsToVisit();
    }
    this.checkRouterParams();
    this.$store.commit(COMMON_SET_LOADING, false);
  },
  watch: {
    'requestForm.DATE_BEGIN': function(newTime){
      if (!newTime) {
        this.isEmptyDateBegin = true;
        return
      }
      this.isEmptyDateBegin = false;
    },
    'requestForm.DATE_END': function(newTime){
      if (!newTime) {
        this.isEmptyDateEnd = true;
        return
      }
      this.isEmptyDateEnd = false;
    },
    'newDateVisit.date': function(newTime){
      if (!newTime) {
        this.isEmptyDatePass = true;
        return
      }
      this.isEmptyDatePass = false;
    },
    'newDateVisit.startTime': function(startTime){
      if (!startTime) {
        this.isEmptyStartTimePass = true;
        return
      }
      this.isEmptyStartTimePass = false;
    },
    'newDateVisit.endTime': function(endTime){
      if (!endTime) {
        this.isEmptyEndTimePass = true;
        return
      }
      this.isEmptyEndTimePass = false;
    },
  },
  computed: {
    ...mapGetters(['getActiveObjectsToVisit', 'getObjectsToVisit', 'carsGroupedByBrand', 'carsBrands']),
    carsModels() {
      return this.$store.getters.carsGroupedByBrand[this.requestForm.CURRENT_BRAND.value]
      ?.map((car) => ({ text: car.NAME, value: car.ID }));
    },
    requestStatuses() {
      return this.enumRequestState.filter(
        (status) =>
          this.requestForm.IS_MOD?.edit_status.includes(status.value) ||
          this.requestForm.STATE === status.value
      );
    },
    isUpdateRequestFormDisabledInput() {
     return this.isNewRequestForm ? false : !this.requestForm.IS_MOD?.parameters;
    },
    isNewRequestForm() {
      return !!this.$route.params.object_id;
    },
    disabledStatusInput() {
      return this.isNewRequestForm || this.requestStatuses.length === 0;
    },
    isParkingPass() {
      return this.getActiveObjectsToVisit.REQUEST_TYPE === REQUEST_TYPES
      .find((type)=> type.text === 'Parking pass').value;
    },
    isBtnDisabled() {
      return !this.formValid
        || this.isEmptyDatePass
        || this.isEmptyStartTimePass
        || this.isEmptyEndTimePass;
    }
  },
  methods: {
    ...mapActions(['fetchObjectsToVisit', 'updateActiveObject']),
    async checkRouterParams() {
      if (this.$route.params.object_id) {
        this.updateActiveObject(this.$route.params.object_id);
        this.requestForm.STATE = this.getActiveObjectsToVisit.DEFAULT_PASS_STATUS_FINAL
        return
      }
      await api.requests.search({id: this.$route.params.id_pass}).then((res) => {
        const request = res.data.DATA[0];
        this.requestForm = this.createRequestFormModel(request);
        this.updateActiveObject(request.OBJECT_ID);
      })
    },
    createRequestFormModel(request = { DATA: {} }) {
      return {
        ID: request.ID || '',
        IS_MOD: request.IS_MOD || null,
        TYPE_ID: request.TYPE_ID || null,
        INSPECTION: request.INSPECTION ?? false,
        OBJECT_ID: request.OBJECT_ID || null,
        MULTI_PASS:
          typeof request.MULTI_PASS === 'boolean' ? request.MULTI_PASS : true,

        DATE_BEGIN: moment(request.DATE_BEGIN).toDate() || null,
        DATE_END: moment(request.DATE_END).toDate() || null,
        DATE_FIRST_PASS: request.DATE_FIRST_PASS
          ? moment(request.DATE_FIRST_PASS).toDate()
          : null,
        INSIDE: request.INSIDE ?? null,
        SEND_ERR: request.SEND_ERR ?? null,
        STATE: request.STATE || 0,
        CREATE_USER_ID: request.CREATE_USER_ID || null,
        IDENTIFIER: request.IDENTIFIER || null,
        CAR_NUM: request.CODE || null,
        CURRENT_BRAND: {value: request.DATA.CAR_BRAND,
          text: request.DATA.CAR_BRAND_NAME} || {},
        CURRENT_MODEL: {value: request.DATA.CAR_MODEL,
          text: request.DATA.CAR_MODEL_NAME} ||{},
        COMMENTS: request.COMMENTS || '',
        DATA: {
          V_PHONE: request.DATA.V_PHONE || '',
          V_NAME: request.DATA.V_NAME || '',
          V_EMAIL: request.DATA.V_EMAIL || '',
          NOTICE: request.DATA.NOTICE || '',
        },
        lang_web: this.$i18n.locale,
      };
    },
    async formSubmit() {
      try {
        this.addNewAndUpdateParkingRequest();
      } catch (error) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t(error.response.data.MESSAGE)
        );
      }
    },
    async addNewAndUpdateParkingRequest() {
      const data = {
        code: this.requestForm.CAR_NUM,
        object_id: this.getActiveObjectsToVisit.ID,
        type_id: this.getActiveObjectsToVisit.REQUEST_TYPE,
        id: this.requestForm.ID,
        date_begin: this.requestForm.DATE_BEGIN
          ? moment(this.requestForm.DATE_BEGIN).format('DD.MM.YYYY HH:mm:00')
          : null,
        date_end: this.requestForm.DATE_END
          ? moment(this.requestForm.DATE_END).format('DD.MM.YYYY HH:mm:00')
          : null,
        inspection: this.requestForm.INSPECTION,
        multi_pass: this.requestForm.MULTI_PASS,
        state: this.requestForm.STATE,
        data: JSON.stringify({
          V_NAME: this.requestForm.DATA.V_NAME,
          V_PHONE: this.requestForm.DATA.V_PHONE,
          V_EMAIL: this.requestForm.DATA.V_EMAIL,
          CAR_BRAND: this.requestForm.CURRENT_BRAND.value,
          CAR_MODEL: this.requestForm.CURRENT_MODEL.value,
          CAR_BRAND_NAME: this.requestForm.CURRENT_BRAND.text,
          CAR_MODEL_NAME: this.requestForm.CURRENT_MODEL.text,
          NOTICE: this.requestForm.DATA.NOTICE ?? '',
        }),
      }
      if (!this.isParkingPass) {
        data.date_begin = moment(
          this.combineDateWithTime(this.newDateVisit.date, this.newDateVisit.startTime)
        ).format('DD.MM.YYYY HH:mm:00');
        data.date_end = moment(
          this.combineDateWithTime(this.newDateVisit.date, this.newDateVisit.endTime)
        ).format('DD.MM.YYYY HH:mm:00');
      }

      if (this.isNewRequestForm) {
        await api.requests.create(data).then(()=> {
          this.$store.dispatch(SNACK_ADD_MESSAGE,
            {
              text:'Пропуск успешно создан',
              color: 'success',
            }
          );
        }).catch(()=> {
          this.$store.dispatch(SNACK_ADD_MESSAGE,
            {
              text:'Что-то пошло не так, попробуйте еще раз',
              color: 'red',
            }
          );
        })
        .finally(()=> {
          this.requestForm = this.createRequestFormModel();
          this.$router.push({
            path: `/requests`,
          });
        });
      } else {
        await api.requests.update(this.requestForm.ID, data).then(()=> {
          this.$store.dispatch(SNACK_ADD_MESSAGE,
            {
              text:'Пропуск успешно изменен',
              color: 'success',
            }
          );
        }).catch(()=> {
          this.$store.dispatch(SNACK_ADD_MESSAGE,
            {
              text:'Что-то пошло не так, попробуйте еще раз',
              color: 'red',
            }
          );
        })
      }
    },
    combineDateWithTime(date = new Date(), time = new Date()) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const hours = time.getHours();
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      return new Date(year, month, day, hours, minutes, seconds);
    },
    receiveColorStates(value) {
      return value ? '#ff5252' : 'primary';
    },
    setNewDateBegin(time) {
      this.requestForm.DATE_BEGIN = time;
    },
    setNewDateEnd(time) {
      this.requestForm.DATE_END = time;
    },
    updateCurrent(value, targetArray, targetProperty) {
      if (targetProperty === 'CURRENT_BRAND') {
        this.requestForm.CURRENT_MODEL = {};
      }
      const selectedCar = this[targetArray].find((car) => car.value === value);
      this.requestForm[targetProperty] = selectedCar;
    },
    updateMultiPass(value) {
      this.requestForm.MULTI_PASS = value;
    },
    remove() {
      api.requests
        .remove(this.requestForm.ID)
        .then((res) => {
          if (res.data.DATA) {
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              color: 'success',
              text: this.$t('Pass successfully deleted'),
            });
            this.$router.push({
              path: `/requests`,
            });
          }
        })
        .catch((error) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, {
            color: 'red',
            text: this.$t(error.response.data.MESSAGE)
          });
        });
    },
    setNewDateParkingPass(date) {
      this.newDateVisit.date = date;
    },
    setStartTimeParkingPass(startTime) {
      this.newDateVisit.startTime = startTime;
    },
    setEndTimeParkingPass(endTime) {
      this.newDateVisit.endTime = endTime;
    },
  }
}
</script>

<template>
  <div class="dialog-mobile-requests">
    <div class="pl-4 pr-4 mt-3">
      <AppBreadcrumbs/>
    </div>
    <div class="mobile-requests__dialog-top">
      <div class="mobile-requests__dialog-title">
        {{ isNewRequestForm? $t('Parking pass'): $t('Editing a parking pass') }}
      </div>
    </div>
    <div class="pl-4 pr-4 pb-5">
      <AppUiInfoBadge>
        {{ getActiveObjectsToVisit.NAME }}
      </AppUiInfoBadge>
      <v-card-text v-if="isParkingPass">
        <v-form
          ref="requestForm"
          fast-fail
          v-model="formValid"
        >
          <v-select
            :rules="carFormRules.STATE"
            v-model="requestForm.STATE"
            :items="requestStatuses"
            :disabled="disabledStatusInput"
            :menu-props="{ offsetY: true }"
            class="mb-5"
            append-icon="$down"
            hide-details="auto"
            outlined
          >
            <template #label>
              {{ $t('Status') }}<span class="red--text">*</span>
            </template>
          </v-select>
          <v-text-field
            v-model="requestForm.CAR_NUM"
            :rules="carFormRules.CAR_NUM"
            hide-details="auto"
            class="mb-5"
            outlined
            :disabled="isUpdateRequestFormDisabledInput"
          >
            <template #label>
              {{ $t('License plate') }}<span class="red--text">*</span>
            </template>
          </v-text-field>
            <date-time-picker
              v-model="requestForm.DATE_BEGIN"
              class="mb-5"
              :disabled="isUpdateRequestFormDisabledInput"
              :class="{
                'date-time-picker__error': isEmptyDateBegin,
              }"
              :placeholder="$t('Start of the pass from') + '*'"
              :color="receiveColorStates(isEmptyDateBegin)"
              @getDate="setNewDateBegin"
            />

            <date-time-picker
              v-model="requestForm.DATE_END"
              @getDate="setNewDateEnd"
              :class="{
                'date-time-picker__error': isEmptyDateEnd,
              }"
              :disabled="isUpdateRequestFormDisabledInput"
              :color="receiveColorStates(isEmptyDateEnd)"
              :placeholder="$t('End of the pass') + '*'"
              class="mb-5"
            />
          <v-autocomplete
            v-model="requestForm.CURRENT_BRAND"
            :items="carsBrands"
            :menu-props="{ offsetY: true }"
            class="mb-5"
            append-icon="$down"
            hide-details="auto"
            :no-data-text="$t('Data not found')"
            :disabled="isUpdateRequestFormDisabledInput"
            outlined
            @input="
              (value) => updateCurrent(value, 'carsBrands', 'CURRENT_BRAND')
            "
          >
            <template #label>
              {{ $t('Car brand') }}
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model="requestForm.CURRENT_MODEL"
            :items="carsModels"
            :menu-props="{ offsetY: true }"
            :disabled="isUpdateRequestFormDisabledInput && !requestForm.CURRENT_BRAND.value"
            class="mb-5"
            append-icon="$down"
            hide-details="auto"
            :no-data-text="$t('Data not found')"
            outlined
            @input="
              (value) => updateCurrent(value, 'carsModels', 'CURRENT_MODEL')
            "
          >
            <template #label>
              {{ $t('Car model') }}
            </template>
          </v-autocomplete>
          <v-text-field
            v-model="requestForm.DATA.V_NAME"
            hide-details="auto"
            class="mb-5"
            outlined
            :disabled="isUpdateRequestFormDisabledInput"
          >
            <template #label>
              {{ $t('Full name of the visitor') }}
            </template>
          </v-text-field>
          <v-text-field
            v-model="requestForm.DATA.V_PHONE"
            hide-details="auto"
            class="mb-5"
            outlined
            :disabled="isUpdateRequestFormDisabledInput"
          >
            <template #label>
              {{ $t('Phone number') }}
            </template>
          </v-text-field>
          <v-text-field
            v-model="requestForm.DATA.V_EMAIL"
            hide-details="auto"
            class="mb-5"
            outlined
            type="email"
            :disabled="isUpdateRequestFormDisabledInput"
          >
            <template #label>
              {{ $t('Email') }}
            </template>
          </v-text-field>
          <v-textarea
            v-model="requestForm.DATA.NOTICE"
            :label="$t('Comments')"
            :disabled="isUpdateRequestFormDisabledInput"
            class="custom-textarea mb-5"
            rows="4"
            row-height="30"
            hide-details="auto"
            outlined
            no-resize
          >
          </v-textarea>
          <span>
            {{ $t('Reusable pass') }}
          </span>
          <multi-pass-radio-btn-new
            :is-multi-pass-prop="requestForm.MULTI_PASS"
            :allowed-pass-type-prop="getActiveObjectsToVisit.ALLOWED_PASS_TYPE"
            :default-pass-type-prop="getActiveObjectsToVisit.DEFAULT_PASS_TYPE"
            :is-new-request-form-prop="true"
            :rules-props="carFormRules.MULTI_PASS"
            :isDisabledAll="isUpdateRequestFormDisabledInput"
            @update-state-fabric="updateMultiPass"
          >
          </multi-pass-radio-btn-new>
          <div class="mb-1 text-left">
            {{ $t('Inspection required') }}
          </div>
          <v-radio-group
            v-model="requestForm.INSPECTION"
            hide-details="auto"
            class="mb-5 mt-0"
            row
            :disabled="isUpdateRequestFormDisabledInput"
          >
            <v-radio :value="true" class="mr-4">
              <template #label>
                <span class="text--primary">{{ $t('Yes') }}</span>
              </template>
            </v-radio>
            <v-radio :value="false">
              <template #label>
                <span class="text--primary">{{ $t('No') }}</span>
              </template>
            </v-radio>
          </v-radio-group>
          <div class="mb-5">
            <button
              v-if="!isNewRequestForm"
              class="dialog-mobile-requests__delete"
              @click.prevent="requestRemoveConfirm = true"
            >
              <v-icon size="16" class="dialog-mobile-requests__delete-icon">
                $delete
              </v-icon>
              {{ $t('Delete a pass') }}
            </button>
          </div>
          <v-btn
            depressed
            block
            color="primary"
            :class="{
              btn_disabled: !(
                formValid &&
                !!requestForm.DATE_BEGIN &&
                !!requestForm.DATE_END
              ),
            }"
            @click="formSubmit"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-form>
      </v-card-text>
      <v-card-text v-if="!isParkingPass">
        <v-form
          ref="requestForm"
          fast-fail
          v-model="formValid"
        >
          <v-select
            :rules="carFormRules.STATE"
            v-model="requestForm.STATE"
            :items="requestStatuses"
            :disabled="disabledStatusInput"
            :menu-props="{ offsetY: true }"
            class="mb-5"
            append-icon="$down"
            hide-details="auto"
            outlined
          >
            <template #label>
              {{ $t('Status') }}<span class="red--text">*</span>
            </template>
          </v-select>
          <div
            class="date-time-picker mb-5"
          >
            <date-time-picker
              v-model="newDateVisit.date"
              @getDate="setNewDateParkingPass"
              :setting="{
                type: 'date',
                format: 'DD.MM.YYYY',
              }"
              :placeholder="$t('Date of visit') + '*'"
              :class="{
                'date-time-picker__error': isEmptyDatePass,
              }"
              :color="receiveColorStates(isEmptyDatePass)"
              class="mb-5"
            />
          </div>
          <div class="mb-1 text-left grey--text">
            {{ $t('Visit time') }}<span class="red--text">*</span>
          </div>
          <div class="dialog-mobile-requests__date-time-picker">
            {{ $t('From') }}
            <date-time-picker
              v-model="newDateVisit.startTime"
              :setting="{
                type: 'time',
                format: 'HH:mm',
                iconCalendar: '$timeIcon',
              }"
              placeholder=""
              @getDate="setStartTimeParkingPass"
            />
            {{ $t('to') }}
            <date-time-picker
              v-model="newDateVisit.endTime"
              :setting="{
                type: 'time',
                format: 'HH:mm',
                iconCalendar: '$timeIcon',
              }"
              placeholder=""
              @getDate="setEndTimeParkingPass"
            />
          </div>
          <v-text-field
            v-model="requestForm.DATA.V_NAME"
            :rules="carFormRules.V_NAME"
            maxlength="200"
            outlined
          >
            <template #label>
              {{ $t('Full name') }}
              <span class="red--text">*</span>
            </template>
          </v-text-field>
          <v-text-field
            v-model="requestForm.DATA.V_EMAIL"
            maxlength="200"
            outlined
          >
            <template #label>
              {{ $t('Email') }}
            </template>
          </v-text-field>

          <v-text-field
            v-model="requestForm.DATA.V_PHONE"
            v-mask="'+####################'"
            outlined
          >
            <template #label>
              {{ $t("Phone number") }}
            </template>
          </v-text-field>

          <v-text-field
            v-model="requestForm.DATA.NOTICE"
            maxlength="200"
            outlined
          >
            <template #label>
              {{ $t('Note') }}
            </template>
          </v-text-field>
          <v-btn
            color="primary"
            block
            :class="{
              btn_disabled: isBtnDisabled,
            }"
            @click="formSubmit"
          >
            {{ $t("Add") }}
          </v-btn>
        </v-form>
      </v-card-text>
    </div>
    <ConfirmRemoveDialog
      v-if="requestRemoveConfirm"
      @closeDialog="requestRemoveConfirm = false"
      @confirm="remove"
    >
      <template #title>
        {{ $t('Deleting a pass') }}
      </template>
      <template #default>
        {{ $t('Are you sure you want to perform this action?') }}
      </template>
    </ConfirmRemoveDialog>
  </div>
</template>


<style lang="scss">
.mobile-requests {
  &__dialog-title {
    font-size: 16px;
    font-weight: 500;
  }
}

.dialog-mobile-requests {
  &__delete {
    font-size: 14px;
    line-height: 16px;
    color:  $accent-red;
  }

  & &__delete-icon {
    color:  $accent-red;
    fill: $accent-red;
  }

  &__date-time-picker {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
