<script>
import api from '@/api/index.js';
import moment from 'moment';
import {
  REQUEST_STATUSES,
  ALLOWED_PASS_TYPES,
} from '@/constants/index.js';
import DateTimePicker from '@/components/blocks/DateTimePicker.vue';
import { mapGetters, mapMutations } from 'vuex';
import MultiPassRadioBtnNew from '@/components/blocks/MultiPassRadioBtnNew.vue';
import AppStatusList from '@/components/blocks/AppStatusList.vue';
import dateHelpers from '@/helpers/dateHelpers';
import {isNumber} from '@/helpers/helpers';
import SelectedCarList from '@/components/blocks/SelectedCarList.vue'
import _ from 'lodash';
import AppScrollToTopButton from '@/components/blocks/AppScrollToTopButton.vue';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';

export default {
  name: 'MobileRequestsPage',
  components: {
    DateTimePicker,
    MultiPassRadioBtnNew,
    SelectedCarList,
    AppStatusList,
    AppScrollToTopButton,
  },
  data() {
    return {
      isShowPass: false,

      externalObjects: [],
      dialog: false,
      activePass: {},
      activeStatus: {},

      requestForm: this.createRequestFormModel(),
      enumRequestStatus: [...REQUEST_STATUSES],
      requestStatus: [...REQUEST_STATUSES],

      isNewRequestForm: false,

      allowedPassType: null,
      defaultPassType: null,

      searchPlateNum: null,
      listPlateNum: [],

      selectedCar: [],
      filteredSelectedCar: [],
      showListPlateNum: true,

      nothingWasFound: false,
    };
  },
  computed: {
    ...mapGetters(['carsGroupedByBrand', 'carsBrands', 'getInputCode', 'getTypePass']),
  },
  watch: {
    dialog(newVal) {
      if (this.isNewRequestForm) {
        for (const key of Object.keys(this.requestForm)) {
          if (key === 'INSPECTION') {
            this.requestForm[key] = false;
            break;
          }
          this.requestForm[key] = '';
        }
      }
      if (newVal && this.$refs.requestForm) {
        this.$refs.requestForm.resetValidation();
      }
      if (newVal && this.isNewRequestForm) {
        this.requestForm.DATE_BEGIN = dateHelpers.getToday().toDate();
        this.requestForm.DATE_END = dateHelpers.getTomorrow().endOf('day').toDate();
        const isActiveInput = this.defaultPassType === ALLOWED_PASS_TYPES.multiPass;
        this.requestForm.MULTI_PASS = isActiveInput;
      }
    },
  },
  mounted() {
    if(this.getInputCode) {
      this.clickPlateNum({IDENTIFIER: this.getInputCode, TYPE: this.getTypePass});
    }
  },
  methods: {
    ...mapMutations(['updateInputCode', 'updateTypePass']),
    inputSearchPlateNum() {
      this.$nextTick(() => {
        this.searchPlateNum = this.searchPlateNum?.replace(/[^a-zA-ZА-Яа-я0-9 ]/g, '');
        if (!this.searchPlateNum || (this.searchPlateNum?.length <= 2)) {
          this.showListPlateNum = false;
          return this.listPlateNum = []
        }
        this.showListPlateNum = true;
        this.nothingWasFound = false;
        this.debouncedSearch();
      });
    },
    debouncedSearch: _.debounce(function () {
      this.performSearch();
    }, 500),
    performSearch() {
      api.requests.searchGetList({identifier: this.searchPlateNum, search_type: 3, limit: 20})
      .then((res) => {
        if (!res.data.DATA.length) {
          this.nothingWasFound = true;
        }
        this.listPlateNum = res.data.DATA
      })
    },
    showWindowPass() {
      this.isShowPass = true;
      this.$router.push('/requests/objects');
    },
    clickItemPass(pass) {
      this.activePass = pass;
      this.isNewRequestForm = true;
      this.requestForm.STATE = pass.initialStatus;
      this.allowedPassType = pass.allowedPassType;
      this.defaultPassType = pass.defaultPassType;
      this.dialog = true;
    },
    closePassDialog() {
      this.activePass = {};
      this.requestForm= this.createRequestFormModel();
      this.dialog = false;
    },
    createRequestFormModel(request = { DATA: {} }) {
      let DATE_BEGIN = null;
      let DATE_END = null;
      if (request.DATE_BEGIN) {
        DATE_BEGIN = moment(request.DATE_BEGIN).toDate();
      }
      if (request.DATE_END) {
        DATE_END = moment(request.DATE_END).toDate();
      }
      return {
        ID: request.ID || '',
        IS_MOD: request.IS_MOD || null,
        TYPE_ID: request.TYPE_ID || null,
        INSPECTION: request.INSPECTION ?? false,
        OBJECT_ID: request.OBJECT_ID || null,
        MULTI_PASS:
          typeof request.MULTI_PASS === 'boolean' ? request.MULTI_PASS : true,
        DATE_BEGIN: DATE_BEGIN,
        DATE_END: DATE_END,
        DATE_FIRST_PASS: request.DATE_FIRST_PASS
          ? moment(request.DATE_FIRST_PASS).toDate()
          : null,
        INSIDE: request.INSIDE ?? null,
        SEND_ERR: request.SEND_ERR ?? null,
        STATE: request.STATE || 0,
        CREATE_USER_ID: request.CREATE_USER_ID || null,
        IDENTIFIER: request.IDENTIFIER || null,
        CAR_NUM: request.CODE || null,
        CURRENT_BRAND: {
          value: request.DATA.CAR_BRAND,
          text: request.DATA.CAR_BRAND_NAME
        } || {},
        CURRENT_MODEL: {
          value: request.DATA.CAR_MODEL,
          text: request.DATA.CAR_MODEL_NAME
        } ||{},
        COMMENTS: request.COMMENTS || '',
        DATA: {
          V_PHONE: request.DATA.V_PHONE || '',
          V_NAME: request.DATA.V_NAME || '',
          V_EMAIL: request.DATA.V_EMAIL || '',
          NOTICE: request.DATA.NOTICE || '',
        },
        lang_web: this.$i18n.locale,
      };
    },
    clickPlateNum(item) {
      this.$store.commit(COMMON_SET_LOADING, true);
      this.updateInputCode(item.IDENTIFIER);
      this.updateTypePass(item.TYPE);

      this.enumRequestStatus = this.requestStatus.map((item) => {
        item.count = 0;
        return item
      })
      this.searchPlateNum = item.IDENTIFIER;
      const data = item.TYPE === 1 ? {v_name: this.searchPlateNum}
      : {code: this.searchPlateNum}
      api.requests.search(data).then((res) => {
        this.listPlateNum = item.IDENTIFIER;
        this.selectedCar = res.data.DATA;
        this.filteredSelectedCar = res.data.DATA;

        res.data.DATA.forEach((item) => {
          this.enumRequestStatus = this.enumRequestStatus.map((elem) => {
            if (elem.value === item.STATE) {
              elem.text = item.STATE_NAME
              if(isNumber(elem.count)) {
                elem.count += 1
                return elem
              }
                elem.count = 1;
            }
            return elem
          })
        })
        this.enumRequestStatus = this.enumRequestStatus.filter((item) => item.count);
        this.showListPlateNum = false;
      }).finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    clickStatusFilter(activeStatus = {}) {
      if (activeStatus.value === this.activeStatus.value) {
        this.activeStatus = {}
        this.filteredSelectedCar = [...this.selectedCar];
        return
      }
      this.activeStatus = activeStatus;
      this.filteredSelectedCar = this.selectedCar.filter((item)=>item.STATE === this.activeStatus.value)
    },
    updateSelectedCar({oldCar, newCar}) {
      this.selectedCar = this.selectedCar.map((item)=>{
        if (newCar.ID === item.ID) {
          item = newCar;
        }
          return item
        })
      this.filteredSelectedCar = this.selectedCar.filter((item)=>{
        const isActiveStatus = this.activeStatus.value || this.activeStatus.value === 0 ;
          if (isActiveStatus) {
            return item.STATE === this.activeStatus.value;
          }
          return true
      })
      this.enumRequestStatus = this.enumRequestStatus.map((elem)=>{
        if(oldCar.STATE === elem.value) {
          elem.count -= 1;
        }
        if(newCar.STATE === elem.value) {
          elem.count += 1;
        }
        return elem;
      });
      this.enumRequestStatus = this.enumRequestStatus.filter((item) => item.count)
    },
    editPass(pass) {
      this.$router.push({
        path: `/requests/edit-pass/${pass.ID}`,
      });
    },
  },
};
</script>

<template>
  <div class="mobile-requests">
    <div v-if="!isShowPass">
      <div class="mobile-requests__search">
        <div class="mobile-requests__search-box">
          <v-text-field
            class="mobile-requests__search-input"
            v-model="searchPlateNum"
            dense
            outlined
            hide-details="auto"
            clearable
            clear-icon="$closeCircle"
            :label="$t('Enter license plate number or full name')"
            @input="inputSearchPlateNum"
            :rules="[(v)=> !v || v?.length >= 3 || this.$t('Enter at least 3 characters')]"
          >
            <template #prepend-inner>
              <v-icon class="pt-1 mr-1 grey--text" size="20">
                $search
              </v-icon>
            </template>
          </v-text-field>
          <v-btn
            depressed
            color="primary"
            @click="showWindowPass"
            min-width="34"
          >
            <v-icon color="white" size="16">
              $plus
            </v-icon>
          </v-btn>
        </div>
        <span
          class="mobile-requests__search-text"
          v-show="listPlateNum?.length >= 20 && searchPlateNum"
        >
          {{ $t('Not all numbers are displayed. For a more accurate search, please specify the entered GRZ') }}
        </span>
        <AppStatusList
          v-if="!showListPlateNum && selectedCar.length"
          :requestStatus="enumRequestStatus"
          :activeStatus="activeStatus"
          @clickStatusFilter="clickStatusFilter"
        >
        </AppStatusList>
      </div>
      <div class="list-plate-num" v-if="showListPlateNum">
        <div
          v-show="listPlateNum.length"
          class="list-plate-num__item"
          v-for="item in listPlateNum"
          :key="item.ID"
          @click="clickPlateNum(item)"
        >
          {{ item.IDENTIFIER }}
        </div>
      </div>
      <SelectedCarList
        v-else-if="selectedCar.length"
        :filteredSelectedCar="filteredSelectedCar"
        @updateSelectedCar="updateSelectedCar"
        @editPass="editPass"
      >
      </SelectedCarList>
      <div v-show="!searchPlateNum && !selectedCar.ID" class="mobile-requests__empty">
        <v-icon size="48" color="primary">
          $notFoundIcon
        </v-icon>
        <span class="mobile-requests__empty-text">
          {{ $t('Enter the car number to display the passes') }}
        </span>
      </div>
      <div v-show="nothingWasFound" class="mobile-requests__empty">
        <v-icon size="48" color="primary">
          $searchQueryIcon
        </v-icon>
        <span class="mobile-requests__empty-text">
          {{ $t('Nothing was found for your query.') }}
          {{ $t('Try again.') }}
        </span>
      </div>
      <div class="mobile-requests__sticky-car-number" v-sticky>
        {{ searchPlateNum }}
      </div>
    </div>
    <app-scroll-to-top-button/>
  </div>
</template>

<style lang="scss">

.mobile-requests {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__container {
    display: flex;
    width: 100%;
    padding: 16px 16px 0;
  }

  &__search {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: $background-primary;
  }

  &__search-box {
    display: flex;
    gap: 12px;
    width: 100%;
  }

  &__search-input {
    width: 100%;

   .v-input__control .v-input__slot {
      background: $white;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 49px;
    margin-top: 153px;
    text-align: center;

    &-text {
      margin-top: 16px;
    }
  }

  &__search-text {
    margin-top: 4px;
  }

  &__dialog-title {
    width: 100%;
    margin-right: 24px;
  }

  &__dialog-top {
    display: flex;
    padding: 0 16px;
    margin-bottom: 12px;
  }

  &__sticky-car-number {
    position: fixed;
    top: 40px;
    right: 0;
    left: 0;
    z-index: 2;
    display: none;
    padding: 12px 28px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.33px;
    background-color: $white;
    border-radius: 0 0 16px 16px;
    box-shadow: 0 0 6.6px 0 #0000002B;
  }
}

.list-plate-num {
  padding: 12px 16px;

  &__item {
    padding: 12px;
    font-size: 16px;
    line-height: 18px;
    background-color: $background-primary;
    border-radius: 4px;
  }

  &__item:not(:last-child) {
    margin-bottom: 12px;
}
}

.item-main {
  &__label {
    font-size: 12px;
    color: $text-secondary-gray;
  }

  &__meaning {
    font-size: 14px;
    color: #000;
  }

}

.content-wrapper {
  overflow: hidden;
  transition: transform 0.5s ease;
  transform: scaleY(0);
  transform-origin: top;
}

.content-wrapper.expanded {
  transform: scaleY(1);
}


.date-time-picker {
  &__error .v-input fieldset {
    border-color: #ff5252;
    border-width: 2px;
  }

  &__error .v-input label {
    color: #ff5252;
  }
}
</style>
