import { SNACK_ADD_MESSAGE, SNACK_SHIFT_MESSAGE } from '../types/action-types';
import { SNACK_SHOW_MESSAGE, SNACK_PUSH_MESSAGE } from '../types/mutation-types';

export default {
  state: {
    snacks: [],
    message: null,
  },
  mutations: {
    [SNACK_SHOW_MESSAGE](state) {
      if (!state.snacks.length > 0) return (state.message = null);
      state.message = state.snacks.pop();
    },
    [SNACK_PUSH_MESSAGE](state, message) {
      state.snacks.unshift(message);
    },
  },
  actions: {
    [SNACK_SHIFT_MESSAGE]({ state, commit }) {
      if (!state.message) {
        commit(SNACK_SHOW_MESSAGE);
      } else {
        state.message = null;
        setTimeout(() => {
          commit(SNACK_SHOW_MESSAGE);
        }, 0);
      }
    },
    [SNACK_ADD_MESSAGE]({ commit, dispatch, state }, message) {
      if (!(typeof message === 'object')) {
        message = { text: message };
      }
      commit(SNACK_PUSH_MESSAGE, message);
      if (!state.message) {
        dispatch(SNACK_SHIFT_MESSAGE);
      }
    },
  },
};
