<template>
  <svg class="srp-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.319 22H6.433C3.989 22 2 20.011 2 17.565V6.436C2 3.99 3.989 2 6.433 2H11.308C13.754 2 15.744 3.99 15.744 6.436V7.368C15.744 7.782 15.408 8.118 14.994 8.118C14.58 8.118 14.244 7.782 14.244 7.368V6.436C14.244 4.816 12.927 3.5 11.308 3.5H6.433C4.816 3.5 3.5 4.816 3.5 6.436V17.565C3.5 19.184 4.816 20.5 6.433 20.5H11.319C12.931 20.5 14.244 19.188 14.244 17.576V16.633C14.244 16.219 14.58 15.883 14.994 15.883C15.408 15.883 15.744 16.219 15.744 16.633V17.576C15.744 20.016 13.758 22 11.319 22Z"
    />
    <mask
      id="mask0_394_2843"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="8"
      y="11"
      width="15"
      height="2"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.99609 11.25H22.537V12.75H8.99609V11.25Z"
      />
    </mask>
    <g mask="url(#mask0_394_2843)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.7871 12.75H9.74609C9.33209 12.75 8.99609 12.414 8.99609 12C8.99609 11.586 9.33209 11.25 9.74609 11.25H21.7871C22.2011 11.25 22.5371 11.586 22.5371 12C22.5371 12.414 22.2011 12.75 21.7871 12.75Z"
      />
    </g>
    <mask
      id="mask1_394_2843"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="18"
      y="8"
      width="5"
      height="8"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1094 8.33545H22.5367V15.6662H18.1094V8.33545Z"
      />
    </mask>
    <g mask="url(#mask1_394_2843)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8591 15.6662C18.6671 15.6662 18.4741 15.5932 18.3281 15.4452C18.0361 15.1512 18.0371 14.6772 18.3301 14.3852L20.7241 12.0002L18.3301 9.6162C18.0371 9.3242 18.0351 8.8502 18.3281 8.5562C18.6201 8.2622 19.0941 8.2622 19.3881 8.5542L22.3161 11.4692C22.4581 11.6092 22.5371 11.8012 22.5371 12.0002C22.5371 12.1992 22.4581 12.3912 22.3161 12.5312L19.3881 15.4472C19.2421 15.5932 19.0501 15.6662 18.8591 15.6662Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LogoutIcon',
};
</script>
