<template>
  <svg
    class="srp-icon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.70597 2.98242C5.32514 2.98242 4.17847 4.10076 4.14597 5.47992V14.4124C4.11597 15.8299 5.23264 16.9966 6.6368 17.0274H13.3726C14.7635 16.9774 15.8643 15.8299 15.8551 14.4166V7.00576L11.9935 2.98242H6.71597H6.70597ZM6.71591 18.2774H6.61091C4.51758 18.2324 2.85091 16.4924 2.89591 14.3991V5.46492C2.94508 3.39742 4.65174 1.73242 6.70341 1.73242H6.71841H12.2592C12.4292 1.73242 12.5917 1.80159 12.7101 1.92409L16.9317 6.32159C17.0426 6.43742 17.1051 6.59326 17.1051 6.75409V14.4124C17.1184 16.5033 15.4884 18.2024 13.3942 18.2774H6.71591Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4765 7.54266H14.1815C12.6557 7.53849 11.4165 6.29516 11.4165 4.77182V2.34766C11.4165 2.00266 11.6965 1.72266 12.0415 1.72266C12.3865 1.72266 12.6665 2.00266 12.6665 2.34766V4.77182C12.6665 5.60849 13.3465 6.29016 14.1832 6.29266H16.4765C16.8215 6.29266 17.1015 6.57266 17.1015 6.91766C17.1015 7.26266 16.8215 7.54266 16.4765 7.54266Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.50087 14.311C7.2093 14.311 6.97266 14.0744 6.97266 13.7828V9.52822C6.97266 9.23664 7.2093 9 7.50087 9C7.79245 9 8.02909 9.23664 8.02909 9.52822V13.7828C8.02909 14.0744 7.79245 14.311 7.50087 14.311Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4994 8.99951C12.7909 8.99951 13.0276 9.23615 13.0276 9.52773L13.0276 13.7823C13.0276 14.0739 12.7909 14.3105 12.4994 14.3105C12.2078 14.3105 11.9712 14.0739 11.9712 13.7823L11.9712 9.52773C11.9712 9.23615 12.2078 8.99951 12.4994 8.99951Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.50013 14.3106C7.35998 14.3106 7.22476 14.2549 7.12616 14.1549L5.4746 12.497C5.26895 12.29 5.26965 11.9554 5.47601 11.7498C5.68307 11.5441 6.01761 11.5441 6.22326 11.7512L7.50013 13.0344L8.77701 11.7512C8.98266 11.5441 9.3172 11.5441 9.52426 11.7498C9.73061 11.9554 9.73132 12.29 9.52566 12.497L7.87411 14.1549C7.77551 14.2549 7.64029 14.3106 7.50013 14.3106Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5001 9.00096C12.6403 9.00096 12.7755 9.0566 12.8741 9.15661L14.5256 10.8145C14.7313 11.0216 14.7306 11.3561 14.5242 11.5618C14.3172 11.7674 13.9826 11.7674 13.777 11.5603L12.5001 10.2771L11.2232 11.5603C11.0176 11.7674 10.683 11.7674 10.476 11.5618C10.2696 11.3561 10.2689 11.0216 10.4746 10.8145L12.1261 9.15661C12.2247 9.0566 12.36 9.00096 12.5001 9.00096Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PaperReplaceIcon',
};
</script>
