<script>
import store from '@/store/index.js';
import { ALLOWED_PASS_TYPES } from '@/constants/index.js';

export default {
  name: 'PassTypes',
  props: {
    propDefaultAllowedPassTypeValue: {
      type: Number,
      default: store.getters.configDefaultAllowedPassType.VALUE,
    },
    propDefaultPassTypeValue: {
      type: Number,
      default: store.getters.configDefaultPassType.VALUE,
    },
    isSettings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allowedPassTypeValue: this.propDefaultAllowedPassTypeValue,
      defaultPassTypeValue: this.propDefaultPassTypeValue,
    };
  },
  computed: {
    isShowDefaultPassType() {
      /* eslint-disable max-len */
      const isSingleAndMultiPass = this.allowedPassTypeValue === ALLOWED_PASS_TYPES.singleAndMultiPass;
      /* eslint-enable max-len */
      return isSingleAndMultiPass;
    },
  },
  watch: {
    propDefaultAllowedPassTypeValue: {
      handler(currentValue) {
        if (currentValue === ALLOWED_PASS_TYPES.singleAndMultiPass) {
          const checkedValue = this.isSettings
            ? ALLOWED_PASS_TYPES.singlePass : ALLOWED_PASS_TYPES.systemValue;
          this.defaultPassTypeValue = checkedValue;
          this.$emit('update-default-pass-type', this.defaultPassTypeValue);
        }
        if (currentValue !== ALLOWED_PASS_TYPES.singleAndMultiPass) {
          this.defaultPassTypeValue = currentValue;
          this.$emit('update-default-pass-type', currentValue);
        }
        this.allowedPassTypeValue = this.propDefaultAllowedPassTypeValue;
      },
    },
    propDefaultPassTypeValue: {
      handler() {
        this.defaultPassTypeValue = this.propDefaultPassTypeValue;
      },
    },
  },
  methods: {
    allowedPassTypes() {
      return Object.entries(ALLOWED_PASS_TYPES)
        .reduce((acc, [passTypeName, value]) => {
          if (this.isSettings && value === ALLOWED_PASS_TYPES.systemValue) {
            return acc;
          }
          return [...acc, { text: this.$t(passTypeName), value }];
        }, []);
    },
    defaultPassTypes() {
      return Object.entries(ALLOWED_PASS_TYPES)
        .reduce((acc, [passTypeName, value]) => {
          if (this.isSettings && value === ALLOWED_PASS_TYPES.systemValue) {
            return acc;
          }
          if (value === ALLOWED_PASS_TYPES.singleAndMultiPass) {
            return acc;
          }
          return [...acc, { text: this.$t(passTypeName), value }];
        }, []);
    },
  },
};
</script>

<template>
  <div>
    <v-select
      v-model="allowedPassTypeValue"
      :items="allowedPassTypes()"
      :menu-props="{ offsetY: true }"
      :label="$t('possibleOmissionOptions')"
      class="mb-5"
      append-icon="$down"
      hide-details="auto"
      outlined
      @change="$emit('update-allowed-pass-type', allowedPassTypeValue)"
    />
    <v-scale-transition>
      <v-select
        v-show="isShowDefaultPassType"
        v-model="defaultPassTypeValue"
        :items="defaultPassTypes()"
        :menu-props="{ offsetY: true }"
        :label="$t('defaultPassType')"
        class="mb-5"
        append-icon="$down"
        hide-details="auto"
        outlined
        @change="$emit('update-default-pass-type', defaultPassTypeValue)"
      />
    </v-scale-transition>
  </div>
</template>
