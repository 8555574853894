import dateHelpers from "@/helpers/dateHelpers";
import {
  FLAG_INSIDE,
  REQUEST_IS_MULTIPASS,
} from "@/constants";

export const tableHeadersLocal = [
  {
    text: "ID",
    value: "ID",
    filterable: false,
    width: "100px",
  },
  {
    text: "Status",
    value: "STATE",
    filterable: false,
    width: "150px",
  },
  {
    text: "Name of visited objects",
    value: "OBJECT_NAME",
    filterable: false,
    sortable: false,
    width: "220px",
  },
  {
    text: "Plate num",
    value: "CODE",
    width: "150px",
  },
  {
    text: "Brand",
    value: "DATA.CAR_BRAND",
    cellClass: "font-weight-medium",
    width: "150px",
  },
  {
    text: "Model",
    value: "DATA.CAR_MODEL",
    cellClass: "font-weight-medium",
    width: "150px",
  },
  {
    text: "Start of the pass",
    value: "DATE_BEGIN",
    filterable: false,
    sortable: false,
    width: "160px",
  },
  {
    text: "End of the pass",
    value: "DATE_END",
    filterable: false,
    sortable: false,
    width: "160px",
  },
  {
    text: "Multipass",
    value: "MULTI_PASS",
    filterable: false,
    sortable: false,
    width: "100px",
  },
  {
    text: "Inspection required",
    value: "INSPECTION",
    filterable: false,
    sortable: false,
    width: "100px",
  },
  {
    text: "Pass inside status",
    value: "INSIDE",
    filterable: false,
    sortable: false,
    width: "180px",
  },
  {
    text: "Date and time of the first pass car",
    value: "DATE_FIRST_PASS",
    filterable: false,
    sortable: false,
    width: "200px",
  },
  {
    text: "Date and time of the last exit car",
    value: "DATE_LAST_EXIT",
    filterable: false,
    sortable: false,
    width: "200px",
  },
  {
    text: "Name of visitor",
    value: "REQUEST_V_NAME",
    filterable: false,
    width: "220px",
  },
  {
    text: "Phone number",
    value: "DATA.V_PHONE",
    filterable: false,
    sortable: false,
    width: "150px",
  },
  {
    text: "Email",
    value: "DATA.V_EMAIL",
    filterable: false,
    sortable: false,
    width: "200px",
  },
  {
    text: "The user who created the pass",
    value: "CREATE_USER_NAME",
    filterable: false,
    sortable: false,
    width: "200px",
  },
  {
    text: "Comments",
    value: "DATA.NOTICE",
    sortable: false,
    width: "250px",
  },
  {
    text: "Actions",
    value: "ACTIONS",
    align: "end",
    divider: true,
    class: "fixed-right-cel",
    cellClass: "fixed-right-cel",
    filterable: false,
    sortable: false,
  },
];

export const tableHeadersExternal = [...tableHeadersLocal];
tableHeadersExternal.splice(
  5,
  0,
  {
    text: "External system",
    value: "SYSTEM_NAME",
    sortable: false,
    width: "200px",
  },
  {
    text: "SID",
    value: "SID",
    sortable: false,
    width: "200px",
  }
);

export const filterModel = [
  {
    name: "STATE",
    type: "select",
    data: null,
    items: [],
    label: "Status",
  },
  {
    name: "OBJECT_ID",
    type: "autocomplete",
    data: [],
    items: [],
    label: "Name of visited objects",
  },
  {
    name: "MULTIPASS",
    type: "select",
    data: null,
    items: REQUEST_IS_MULTIPASS,
    label: "Reusable pass",
  },
  {
    name: "CODE",
    type: "text-field",
    data: null,
    label: "Plate num",
  },
  {
    name: "INSIDE",
    type: "select",
    data: null,
    items: FLAG_INSIDE,
    label: "Pass inside status",
  },
  {
    name: "CREATE_USER_ID",
    type: "select",
    data: null,
    items: [],
    label: "The user who created the pass",
  },
  {
    name: "START_S",
    type: "date-time-picker",
    data: dateHelpers.getDaysAgo().startOf("day").toDate(),
    label: "Start of the pass from",
  },
  {
    name: "START_PO",
    type: "date-time-picker",
    data: dateHelpers.getToday().endOf("day").toDate(),
    label: "Start of the pass till",
  },
  {
    name: "END_S",
    type: "date-time-picker",
    data: null,
    label: "End of the pass from",
  },
  {
    name: "END_PO",
    type: "date-time-picker",
    data: null,
    label: "End of the pass till",
  },
];
