<template>
  <svg
    :class="{
      'srp-icon-down-to-next': mode === 'next',
      'srp-icon-down-to-prev': mode === 'prev',
    }"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="srp-icon"
  >
    <path
      d="M7.96967 19.5303C7.7034 19.2641 7.6792 18.8474 7.89705 18.5538L7.96967 18.4697L14.439 12L7.96967 5.53033C7.7034 5.26406 7.6792 4.8474 7.89705 4.55379L7.96967 4.46967C8.23594 4.2034 8.6526 4.1792 8.94621 4.39705L9.03033 4.46967L16.0303 11.4697C16.2966 11.7359 16.3208 12.1526 16.1029 12.4462L16.0303 12.5303L9.03033 19.5303C8.73744 19.8232 8.26256 19.8232 7.96967 19.5303Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'RightIcon',
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
};
</script>
