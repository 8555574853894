<template>
  <svg class="srp-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.001 16.541a.75.75 0 01-.75-.75V3.751a.75.75 0 011.5 0v12.04a.75.75 0 01-.75.75z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.001 16.541a.746.746 0 01-.53-.22l-2.917-2.928a.751.751 0 011.062-1.059l2.385 2.395 2.385-2.395a.749.749 0 111.062 1.06l-2.916 2.926a.746.746 0 01-.53.221z"
    />
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.575 21.477H6.435A4.44 4.44 0 012 17.042v-4.885a4.43 4.43 0 014.426-4.424h.941a.75.75 0 010 1.5h-.94A2.927 2.927 0 003.5 12.157v4.885a2.938 2.938 0 002.935 2.935h11.14a2.93 2.93 0 002.925-2.925v-4.884a2.938 2.938 0 00-2.934-2.935h-.932a.75.75 0 010-1.5h.932A4.44 4.44 0 0122 12.168v4.884a4.43 4.43 0 01-4.425 4.425z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon',
};
</script>
