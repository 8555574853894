<template>
  <svg class="srp-stroke-icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2814 2.3015H6.73728C5.00394 2.29483 3.58311 3.6765 3.54227 5.409V14.3365C3.50394 16.0973 4.89978 17.5565 6.66061 17.5957C6.68644 17.5957 6.71144 17.5965 6.73728 17.5957H13.3948C15.1398 17.5248 16.5148 16.0832 16.5023 14.3365V6.69817L12.2814 2.3015Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0625 2.29166V4.71583C12.0625 5.89916 13.0192 6.85833 14.2025 6.86166H16.4983"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9066 12.7987H7.40662"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.2026 9.67166H7.40588"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'PaperIcon',
};
</script>
