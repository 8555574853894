<template>
  <svg class="srp-icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.782 2.917a6.873 6.873 0 00-6.866 6.865 6.874 6.874 0 006.866 6.866 6.873 6.873 0 006.865-6.866 6.873 6.873 0 00-6.865-6.865zm0 14.98c-4.475 0-8.116-3.64-8.116-8.115s3.641-8.115 8.116-8.115 8.115 3.64 8.115 8.115-3.64 8.116-8.115 8.116z"
      />
    </g>
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.928 18.935c-.159 0-.319-.061-.441-.183l-2.937-2.928a.625.625 0 01.883-.886l2.937 2.93a.624.624 0 01-.442 1.067z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
};
</script>
