<template>
  <svg class="srp-icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="9" y="1" width="2" height="18" rx="1" />
    <rect x="19" y="9" width="2" height="18" rx="1" transform="rotate(90 19 9)" />
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon',
};
</script>
