<template>
  <svg class="srp-icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.70597 2.98242C5.32514 2.98242 4.17847 4.10076 4.14597 5.47992V14.4124C4.11597 15.8299 5.23264 16.9966 6.6368 17.0274H13.3726C14.7635 16.9774 15.8643 15.8299 15.8551 14.4166V7.00576L11.9935 2.98242H6.71597H6.70597ZM6.71591 18.2774H6.61091C4.51758 18.2324 2.85091 16.4924 2.89591 14.3991V5.46492C2.94508 3.39742 4.65174 1.73242 6.70341 1.73242H6.71841H12.2592C12.4292 1.73242 12.5917 1.80159 12.7101 1.92409L16.9317 6.32159C17.0426 6.43742 17.1051 6.59326 17.1051 6.75409V14.4124C17.1184 16.5033 15.4884 18.2024 13.3942 18.2774H6.71591Z"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4765 7.5429H14.1815C12.6557 7.53873 11.4165 6.2954 11.4165 4.77207V2.3479C11.4165 2.0029 11.6965 1.7229 12.0415 1.7229C12.3865 1.7229 12.6665 2.0029 12.6665 2.3479V4.77207C12.6665 5.60873 13.3465 6.2904 14.1832 6.2929H16.4765C16.8215 6.2929 17.1015 6.5729 17.1015 6.9179C17.1015 7.2629 16.8215 7.5429 16.4765 7.5429Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.68018 13.9729C9.33518 13.9729 9.05518 13.6929 9.05518 13.3479V8.31372C9.05518 7.96872 9.33518 7.68872 9.68018 7.68872C10.0252 7.68872 10.3052 7.96872 10.3052 8.31372V13.3479C10.3052 13.6929 10.0252 13.9729 9.68018 13.9729Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.67972 13.9721C9.51389 13.9721 9.35389 13.9062 9.23722 13.7879L7.28305 11.8262C7.03972 11.5812 7.04055 11.1854 7.28472 10.9421C7.52972 10.6987 7.92555 10.6987 8.16889 10.9437L9.67972 12.4621L11.1906 10.9437C11.4339 10.6987 11.8297 10.6987 12.0747 10.9421C12.3189 11.1854 12.3197 11.5812 12.0764 11.8262L10.1222 13.7879C10.0056 13.9062 9.84555 13.9721 9.67972 13.9721Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PaperDownload',
};
</script>
