<template>
  <svg class="srp-icon" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.191 8l6.562-6.562a.842.842 0 10-1.19-1.191L8 6.809 1.438.247a.842.842 0 10-1.191 1.19L6.809 8 .247 14.562a.842.842 0 001.19 1.191L8 9.191l6.562 6.562a.842.842 0 001.191-1.19L9.191 8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon',
};
</script>
