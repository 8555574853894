<template>
  <svg class="srp-icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.134 13.014a.62.62 0 01-.442-.183 3.229 3.229 0 01-.954-2.3c0-1.8 1.463-3.263 3.261-3.263.865 0 1.705.349 2.305.958a.624.624 0 01-.891.877 1.989 1.989 0 00-1.414-.585c-1.109 0-2.011.903-2.011 2.014 0 .534.209 1.037.587 1.416a.625.625 0 01-.441 1.066zM10.473 13.743a.626.626 0 01-.111-1.24 2.007 2.007 0 001.61-1.614.625.625 0 011.23.222 3.266 3.266 0 01-2.617 2.622.655.655 0 01-.113.01z"
    />
    <mask id="a" maskUnits="userSpaceOnUse" x="1" y="3" width="15" height="13">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.667 3.854h13.405v11.748H1.667V3.854z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.545 15.602a.628.628 0 01-.386-.133c-1.409-1.107-2.6-2.73-3.441-4.691a.621.621 0 010-.494c.85-1.97 2.049-3.601 3.466-4.715 2.888-2.286 6.733-2.294 9.65.016a.625.625 0 11-.775.98c-2.472-1.956-5.656-1.95-8.101-.015-1.197.942-2.224 2.314-2.983 3.982.751 1.659 1.77 3.022 2.957 3.954a.625.625 0 01-.387 1.116z"
      />
    </g>
    <mask id="b" maskUnits="userSpaceOnUse" x="7" y="7" width="12" height="11">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.264 7.284h11.069v9.957H7.264V7.284z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#b)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 17.241c-.781 0-1.558-.127-2.308-.376a.625.625 0 01.395-1.186A6.038 6.038 0 0010 15.99c2.856 0 5.467-2.035 7.025-5.46a12.084 12.084 0 00-1.318-2.246.626.626 0 01.998-.751 13.45 13.45 0 011.576 2.748c.07.158.07.338 0 .496C16.535 14.825 13.44 17.24 10 17.24z"
      />
    </g>
    <mask id="c" maskUnits="userSpaceOnUse" x="2" y="3" width="16" height="15">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.803 3.334h14.394v14.394H2.803V3.334z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#c)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.428 17.728a.624.624 0 01-.442-1.067L16.13 3.516a.624.624 0 11.883.884L3.87 17.544a.62.62 0 01-.441.184z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'HideIcon',
};
</script>
