<template>
  <svg
    class="srp-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Fill 1"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9999 9.64111C10.6699 9.64111 9.58887 10.7231 9.58887 12.0531C9.58887 13.3821 10.6699 14.4631 11.9999 14.4631C13.3299 14.4631 14.4119 13.3821 14.4119 12.0531C14.4119 10.7231 13.3299 9.64111 11.9999 9.64111ZM11.9999 15.9631C9.84287 15.9631 8.08887 14.2091 8.08887 12.0531C8.08887 9.89611 9.84287 8.14111 11.9999 8.14111C14.1569 8.14111 15.9119 9.89611 15.9119 12.0531C15.9119 14.2091 14.1569 15.9631 11.9999 15.9631Z"
    />
    <path
      id="Fill 3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.56975 12.0523C5.42975 16.1613 8.56275 18.6043 11.9998 18.6053C15.4368 18.6043 18.5697 16.1613 20.4298 12.0523C18.5697 7.94426 15.4368 5.50126 11.9998 5.50026C8.56375 5.50126 5.42975 7.94426 3.56975 12.0523ZM12.0017 20.1053H11.9978H11.9967C7.86075 20.1023 4.14675 17.2033 2.06075 12.3483C1.97975 12.1593 1.97975 11.9453 2.06075 11.7563C4.14675 6.90226 7.86175 4.00326 11.9967 4.00026C11.9987 3.99926 11.9987 3.99926 11.9998 4.00026C12.0017 3.99926 12.0017 3.99926 12.0028 4.00026C16.1388 4.00326 19.8527 6.90226 21.9387 11.7563C22.0208 11.9453 22.0208 12.1593 21.9387 12.3483C19.8537 17.2033 16.1388 20.1023 12.0028 20.1053H12.0017Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ShowIcon',
};
</script>
