<template>
  <svg viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M23.5 5.5C13.835 5.5 6 13.335 6 23C6 32.665 13.835 40.5 23.5 40.5C33.165 40.5 41 32.665 41 23C41 13.335 33.165 5.5 23.5 5.5ZM3 23C3 11.6782 12.1782 2.5 23.5 2.5C34.8218 2.5 44 11.6782 44 23C44 28.121 42.1223 32.8035 39.0177 36.3964L45.5607 42.9393C46.1464 43.5251 46.1464 44.4749 45.5607 45.0607C44.9749 45.6464 44.0251 45.6464 43.4393 45.0607L36.8964 38.5177C33.3035 41.6223 28.621 43.5 23.5 43.5C12.1782 43.5 3 34.3218 3 23Z"
      fill="currentColor" />
    <path
      d="M18.9038 18.4038C19.4896 17.818 20.4393 17.818 21.0251 18.4038L23.5 20.8787L25.9749 18.4038C26.5607 17.818 27.5104 17.818 28.0962 18.4038C28.682 18.9896 28.682 19.9393 28.0962 20.5251L25.6213 23L28.0962 25.4749C28.682 26.0607 28.682 27.0104 28.0962 27.5962C27.5104 28.182 26.5607 28.182 25.9749 27.5962L23.5 25.1213L21.0251 27.5962C20.4393 28.182 19.4896 28.182 18.9038 27.5962C18.318 27.0104 18.318 26.0607 18.9038 25.4749L21.3787 23L18.9038 20.5251C18.318 19.9393 18.318 18.9896 18.9038 18.4038Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'notFoundIcon',
};
</script>
