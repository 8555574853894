import store from "@/store";

export const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const badstr = atob(arr[1]);
  let n = badstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = badstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const exportToCsv = (filename, rows = []) => {
  let csvFile = '';
  rows.forEach((el) => {
    csvFile += `${el.join(',')}\r\n`;
  });
  const encodedUri = encodeURI(csvFile);
  const link = document.createElement('a');
  if (link.download !== undefined) {
    link.setAttribute('href', `data:text/csv;charset=utf-8,\uFEFF${encodedUri}`);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const getRequestDataValuesByConfigs = (passageData, configs) => configs.map((config) => {
  if (!passageData[config.SID]) return '';
  return passageData[config.SID];
});

export function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  }
  // text area method
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  // make the textarea out of viewport
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((res, rej) => {
    document.execCommand('copy') ? res(true) : rej();
    textArea.remove();
  });
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Показывает стандартную модалку
 * @param { dialogObject } - данный объект должен содержать свойства
 * 1. title - заголовок модалки
 * 2. text - основной текст модалки
 * 3. functionOnClose - функция, которая выполнится при закрытии модалки
 */
export function showDefaultDialog(dialogObject) {
  store.commit('changeDefaultDialogTitle', dialogObject.title);
  store.commit('changeDefaultDialogText', dialogObject.text);
  store.commit('changeDefaultDialogFunctionOnClose', dialogObject.functionOnClose);
  store.commit('changeDefaultDialogStatus', true);
}

export function getEnumNumberByName(enumKey, name) {
  return store.getters?.getEnums[enumKey]?.find(item => item.NAME === name).NUMBER;
}

export function isNumber(value) {
  return typeof value === 'number' && !isNaN(value);
}

export function findObjectInArrayAndGetItsValue({arr, obj, arrKey, objKey}) {
  return arr.find((elem) => elem[arrKey] === obj[objKey]);
}

export function getCurrentDateWithFixedTime({hours = 0, minutes = 0, seconds = 0}) {
  let currentDate = new Date();
  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);
  currentDate.setSeconds(seconds);
  currentDate.setMilliseconds(0);
  return currentDate;
}
