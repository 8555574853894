<template>
  <div>
    <template v-if="field.SID === 'V_PHONE'">
      <v-text-field
        v-model="fieldModel"
        v-mask="'+####################'"
        :rules="field.IS_NOT_NULL ? [rules.required] : []"
        outlined
      >
        <template #label>
          {{ $i18n.locale === "en" ? $t(field.NAME) : field.NAME }}
          <span v-if="field.IS_NOT_NULL" class="red--text">*</span>
        </template>
      </v-text-field>
    </template>
    <template v-else>
      <v-text-field
        v-model="fieldModel"
        :rules="field.IS_NOT_NULL ? [rules.required] : []"
        maxlength="200"
        outlined
      >
        <template #label>
          {{ $i18n.locale === "en" ? $t(field.NAME) : field.NAME }}
          <span v-if="field.IS_NOT_NULL" class="red--text">*</span>
        </template>
      </v-text-field>
    </template>
  </div>
</template>

<script>
export default {
  name: 'RequestFormField',
  props: {
    field: { type: Object, required: true },
    value: { type: String, required: true },
  },
  computed: {
    rules() {
      return {
        required: (v) => !!v || this.$t('Required field'),
      };
    },
    fieldModel: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit('input', value);
      },
    },
  },
};
</script>
