<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.20833 1.66699C8.20833 1.32181 8.48816 1.04199 8.83333 1.04199H12.1667C12.5118 1.04199 12.7917 1.32181 12.7917 1.66699C12.7917 2.01217 12.5118 2.29199 12.1667 2.29199H8.83333C8.48816 2.29199 8.20833 2.01217 8.20833 1.66699ZM10.5 3.95866C6.70304 3.95866 3.625 7.0367 3.625 10.8337C3.625 14.6306 6.70304 17.7087 10.5 17.7087C14.297 17.7087 17.375 14.6306 17.375 10.8337C17.375 7.0367 14.297 3.95866 10.5 3.95866ZM2.375 10.8337C2.375 6.34635 6.01269 2.70866 10.5 2.70866C14.9873 2.70866 18.625 6.34635 18.625 10.8337C18.625 15.321 14.9873 18.9587 10.5 18.9587C6.01269 18.9587 2.375 15.321 2.375 10.8337ZM10.5 6.87533C10.8452 6.87533 11.125 7.15515 11.125 7.50033V10.8337C11.125 11.1788 10.8452 11.4587 10.5 11.4587C10.1548 11.4587 9.875 11.1788 9.875 10.8337V7.50033C9.875 7.15515 10.1548 6.87533 10.5 6.87533Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'TimeIcon',
};
</script>
