<template>
  <div>
    <button
      v-if="isVisible"
      @click="scrollToTop"
      class="scroll-to-top"
    >
      <v-icon size="24" class="scroll-to-top__icon">
        $up
      </v-icon>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      this.isVisible = window.scrollY > 200;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss">
.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: block;
  padding: 8px;
  cursor: pointer;
  background: $white;
  border-radius: 50px;
  box-shadow: 0 1px 6.2px 0 #6E8BA454;

  & &__icon {
    color: $primary;
  }
}
</style>
