<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="srp-icon"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.31119 2.92676C4.93036 2.92676 3.78369 4.04509 3.75119 5.42426V14.3568C3.72119 15.7743 4.83786 16.9409 6.24203 16.9718H12.9779C14.3687 16.9218 15.4695 15.7743 15.4604 14.3609V6.95009L11.5987 2.92676H6.32119H6.31119ZM6.32113 18.2218H6.21613C4.1228 18.1768 2.45613 16.4368 2.50113 14.3434V5.40926C2.5503 3.34176 4.25697 1.67676 6.30863 1.67676H6.32363H11.8645C12.0345 1.67676 12.197 1.74592 12.3153 1.86842L16.537 6.26592C16.6478 6.38176 16.7103 6.53759 16.7103 6.69842V14.3568C16.7236 16.4476 15.0936 18.1468 12.9995 18.2218H6.32113Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0817 7.48699H13.7867C12.2608 7.48283 11.0217 6.23949 11.0217 4.71616V2.29199C11.0217 1.94699 11.3017 1.66699 11.6467 1.66699C11.9917 1.66699 12.2717 1.94699 12.2717 2.29199V4.71616C12.2717 5.55283 12.9517 6.23449 13.7883 6.23699H16.0817C16.4267 6.23699 16.7067 6.51699 16.7067 6.86199C16.7067 7.20699 16.4267 7.48699 16.0817 7.48699Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.28479 13.917C8.93979 13.917 8.65979 13.637 8.65979 13.292V8.25781C8.65979 7.91281 8.93979 7.63281 9.28479 7.63281C9.62979 7.63281 9.90979 7.91281 9.90979 8.25781V13.292C9.90979 13.637 9.62979 13.917 9.28479 13.917Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.33091 10.8457C7.17174 10.8457 7.01174 10.7849 6.89008 10.6632C6.64591 10.4199 6.64424 10.0249 6.88841 9.77986L8.84174 7.81652C9.07674 7.58069 9.49341 7.58069 9.72758 7.81652L11.6826 9.77986C11.9259 10.0249 11.9251 10.4199 11.6809 10.6632C11.4359 10.9065 11.0401 10.9065 10.7967 10.6615L9.28508 9.14319L7.77341 10.6615C7.65174 10.7849 7.49091 10.8457 7.33091 10.8457Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PaperUpload',
};
</script>
