
export default {
  state: {
    inputCode: null,
    typePass: null,
  },
  getters: {
    getInputCode: (state) => {
      return state.inputCode;
    },
    getTypePass: (state) => {
      return state.typePass;
    },
  },
  mutations: {
    updateInputCode(state, newInputCode) {
      state.inputCode = newInputCode;
    },
    updateTypePass(state, newTypePass) {
      state.typePass = newTypePass;
    },
  },

};
