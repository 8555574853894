import axios from 'axios';

export const user = () => axios({
  url: '/session/user',
  method: 'GET',
});

export const updateActivity = () => axios({
  url: '/session/update-activity',
  method: 'POST',
});

export const changePassword = ({ OLD_PASSWORD, NEW_PASSWORD }) => axios({
  url: '/session/password',
  method: 'PATCH',
  data: { OLD_PASSWORD, NEW_PASSWORD },
});
