<template>
  <svg class="srp-stroke-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2C6.48566 2 2 6.48566 2 12C2 17.5143 6.48566 22 12 22C17.5143 22 22 17.5136 22 12C22 6.48645 17.5143 2 12 2ZM12 20.4508C7.34082 20.4508 3.54918 16.66 3.54918 12C3.54918 7.34004 7.34082 3.54918 12 3.54918C16.6592 3.54918 20.4508 7.34004 20.4508 12C20.4508 16.66 16.66 20.4508 12 20.4508Z"
    />
    <path
      d="M15.3334 11.2499H12.6667V8.58319C12.6667 8.21518 12.3687 7.9165 12.0001 7.9165C11.6314 7.9165 11.3334 8.21518 11.3334 8.58319V11.2499H8.66669C8.29801 11.2499 8 11.5486 8 11.9166C8 12.2846 8.29801 12.5832 8.66669 12.5832H11.3334V15.2499C11.3334 15.6179 11.6314 15.9166 12.0001 15.9166C12.3687 15.9166 12.6667 15.6179 12.6667 15.2499V12.5832H15.3334C15.7021 12.5832 16.0001 12.2846 16.0001 11.9166C16.0001 11.5486 15.7021 11.2499 15.3334 11.2499Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AddIcon',
};
</script>
