import axios from 'axios';

export const search = (params) => axios({
  url: '/requests',
  method: 'GET',
  params,
});

export const searchGetList = (params) => axios({
  url: '/requests/get-list',
  method: 'GET',
  params,
});

export const uploadPackFile = (fileFormData) => axios({
  url: '/requests/pack',
  method: 'POST',
  data: fileFormData,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const create = (requestFormData) => axios({
  url: '/requests',
  method: 'POST',
  data: requestFormData,
});

export const update = (id, requestFormData) => {
  Object.assign(requestFormData, { '_method' : 'POST' });
  return axios({
    url: `/requests/${id}`,
    method: 'POST',
    data: requestFormData,
  });
};

export const changeState = (id, state) => {
  return axios({
    url: `/requests/${id}/change-state/${state}`,
    method: 'PUT',
  });
};

export const remove = (id) => axios({
  url: `/requests/${id}`,
  method: 'DELETE',
});

export const show = (id) => axios({
  url: `/requests/${id}`,
  method: 'GET',
});

export const resendEmail = (id) => axios({
  url: `/requests/resend-email/${id}`,
  method: 'POST',
});
