<template>
  <div class="d-flex mt-16">
    <div class="container">
      <v-row>
        <v-col class="d-flex align-center flex-column">
          <h3 class="text-h3 text-center mt-16 mb-12">
            {{ $t("Order a one-time pass") }}
          </h3>
          <v-text-field
            v-model="searchByObjectName"
            dense
            outlined
            hide-details="auto"
            single-line
            clearable
            clear-icon="$closeCircle"
            :label="$t('Search by name')"
            style="width: 100%; max-width: 647px"
            class="mb-5 search-input"
            @input="customFilter"
          >
            <template #prepend-inner>
              <v-icon class="pt-1 mr-1 grey--text" size="20">$search</v-icon>
            </template>
          </v-text-field>

          <template v-if="objectsList.length === 0 && !searchByObjectName && firstMount">
            <p>
              {{ $t("There are no objects.") }}
            </p>
          </template>
          <template v-if="!objectsList.length > 0 && searchByObjectName">
            <p>
              {{ $t("Nothing was found according to your request.") }}
            </p>
          </template>
          <template v-else-if="!objectsList.length > 0 && !firstMount">
            <v-skeleton-loader
              class="srp-object-card-skeleton mb-4"
              width="100%"
              max-width="647"
              type="button"
            />
            <v-skeleton-loader
              class="srp-object-card-skeleton mb-4"
              width="100%"
              max-width="647"
              type="button"
            />
            <v-skeleton-loader
              class="srp-object-card-skeleton mb-4"
              width="100%"
              max-width="647"
              type="button"
            />
            <v-skeleton-loader
              class="srp-object-card-skeleton mb-4"
              width="100%"
              max-width="647"
              type="button"
            />
          </template>

          <v-data-iterator
            v-if="objectsList.length > 0"
            :items="objectsList"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            hide-default-footer
            class="d-flex flex-column align-center"
            style="width: 100%"
          >
            <template #default="props">
              <v-card
                v-for="object in props.items"
                :key="object.ID"
                outlined
                width="100%"
                max-width="647"
                class="mb-4 objects-list__item"
              >
                <v-card-text class="px-7 py-4 d-flex align-center">
                  <v-avatar size="40" class="mr-7" :class="{ 'srp-border': object.LOGO }">
                    <img
                      :src="
                        object.LOGO
                          ? convertObjectLogo(object.LOGO)
                          : require('@/assets/images/default-avatar.jpg')
                      "
                      :style="{
                        width: object.LOGO ? '25px' : 'inherit',
                        height: object.LOGO ? '25px' : 'inherit',
                      }"
                      alt="logo"
                    >
                  </v-avatar>
                  <span class="text--primary text-body-1">
                    {{ object.NAME }}
                  </span>
                  <v-spacer />
                  <v-btn small color="primary" @click="$emit('selectObject', object)">
                    {{ $t("Select") }}
                  </v-btn>
                </v-card-text>
              </v-card>
            </template>

            <template #footer>
              <v-row
                v-if="numberOfPages > 1"
                class="mt-2 d-flex justify-end align-center"
                style="width: 647px"
              >
                <span class="mr-4 grey--text">
                  {{ $t("Page") }} {{ page }} {{ $t("of") }} {{ numberOfPages }}
                </span>
                <v-btn fab dark color="blue darken-3" class="mr-1" @click="formerPage">
                  <v-icon>$left</v-icon>
                </v-btn>
                <v-btn fab dark color="blue darken-3" class="ml-1" @click="nextPage">
                  <v-icon>$right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </div>
    <div class="top-right d-flex align-center">
      <v-btn class="" color="primary" @click="$store.dispatch('logout')">
        {{ $t("Login") }}
      </v-btn>
      <lang-btn class="ml-5 mr-2" />
    </div>
    <a :href="`https://infomatika.ru/${$i18n.locale === 'en' ? 'en' : ''}`">
      <v-img class="logo" contain src="https://infomatika.ru/local/static_v2/img/logo.svg" />
    </a>
  </div>
</template>

<script>
import api from '@/api';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import LangBtn from '@/components/blocks/LangBtn.vue';
import { OBJECT_STATUSES, OBJECT_SYSTEM_ID } from '@/constants/index';

export default {
  name: 'SelfRegistrationObjects',
  components: { LangBtn },
  data() {
    return {
      objectsList: [],
      searchByObjectName: '',
      page: 1,
      itemsPerPage: 6,
      data: [],
      firstMount: false,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.objectsList.length / this.itemsPerPage);
    },
  },
  mounted() {
    api.objects
      .getAll()
      .then((res) => {
        this.data = res.data.DATA;

        res.data.DATA.forEach((object) => {
          if (object.STATUS === this.getEnumValueByName('Active') && object.SYSTEM_ID === OBJECT_SYSTEM_ID.local) {
            this.objectsList.push(object);
          }
        });

        if (this.objectsList.length === 0) this.firstMount = true;
      })
      .catch((error) => {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t(error.response.data.MESSAGE) || this.$t('Unknown error!'),
        );
      });
  },
  methods: {
    customFilter() {
      this.objectsList = [];

      this.data.filter((object) => {
        if (object.NAME.includes(this.searchByObjectName)) {
          if (object.STATUS === this.getEnumValueByName('Active')  && object.SYSTEM_ID === OBJECT_SYSTEM_ID.local) {
            this.objectsList.push(object);
          }
        }
      });

      Math.ceil(this.objectsList.length / this.itemsPerPage);

      return this.objectsList;
    },
    convertObjectLogo(logo) {
      if (!logo) return '';
      if (logo.includes('data:image/png;base64,')) return logo;
      return `data:image/png;base64, ${logo}`;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    getEnumValueByName(text) {
      return OBJECT_STATUSES.find(item => item.text === text).value;
    }
  },
};
</script>

<style scoped lang="scss">
.top-right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
}

.objects-list {
  &__item {
    border: 1px solid $srp-gray-color;
  }
}
</style>
