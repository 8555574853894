<script>
export default {
  name: 'AppUiInfoBadge',
}
</script>

<template>
  <div class="app-ui-info-badge">
    <slot></slot>
  </div>
</template>


<style lang="scss">
  .app-ui-info-badge {
    display: inline-block;
    padding: 6px 20px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: $primary;
    background: $background-primary;
    border-radius: 50px;
  }
</style>
