import axios from 'axios';

export const getAll = (params) => axios({
  url: '/objects',
  method: 'GET',
  params,
});

export const create = (objectFormData) => axios({
  url: '/objects',
  method: 'POST',
  data: objectFormData,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const update = (id, objectFormData) => {
  // обход проблем с методом PUT в Laravel
  objectFormData.append('_method', 'PUT');
  return axios({
    url: `/objects/${id}`,
    method: 'POST',
    data: objectFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const remove = (id) => axios({
  url: `/objects/${id}`,
  method: 'DELETE',
});

export const getZones = (id) => axios({
  url: `/objects/${id}/zones`,
  method: 'GET',
});

export const bindZones = ({ zone_id, object_id, is_bind }) => axios({
  url: `/objects/${object_id}/bind-zone`,
  method: 'PATCH',
  data: { zone_id, is_bind },
});
