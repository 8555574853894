<template>
  <svg viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5 5.5C13.835 5.5 6 13.335 6 23C6 32.665 13.835 40.5 23.5 40.5C33.165 40.5 41 32.665 41 23C41 13.335 33.165 5.5 23.5 5.5ZM3 23C3 11.6782 12.1782 2.5 23.5 2.5C34.8218 2.5 44 11.6782 44 23C44 28.121 42.1223 32.8035 39.0177 36.3964L45.5607 42.9393C46.1464 43.5251 46.1464 44.4749 45.5607 45.0607C44.9749 45.6464 44.0251 45.6464 43.4393 45.0607L36.8964 38.5177C33.3035 41.6223 28.621 43.5 23.5 43.5C12.1782 43.5 3 34.3218 3 23Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 18.792C21.5 17.7565 22.3395 16.917 23.375 16.917C24.4105 16.917 25.25 17.7565 25.25 18.792C25.25 19.4779 24.8824 20.0793 24.3274 20.4076C23.8632 20.6823 23.3394 21.0604 22.9193 21.5553C22.4941 22.0564 22.125 22.7405 22.125 23.5837V25.667C22.125 26.3573 22.6846 26.917 23.375 26.917C24.0654 26.917 24.625 26.3573 24.625 25.667V23.5837C24.625 23.5063 24.6542 23.3747 24.8255 23.1729C25.0018 22.9651 25.2725 22.7533 25.6004 22.5593C26.8844 21.7996 27.75 20.3976 27.75 18.792C27.75 16.3757 25.7912 14.417 23.375 14.417C20.9588 14.417 19 16.3757 19 18.792C19 19.4823 19.5596 20.042 20.25 20.042C20.9404 20.042 21.5 19.4823 21.5 18.792ZM23.375 32.3337C24.2955 32.3337 25.0417 31.5875 25.0417 30.667C25.0417 29.7465 24.2955 29.0003 23.375 29.0003C22.4545 29.0003 21.7083 29.7465 21.7083 30.667C21.7083 31.5875 22.4545 32.3337 23.375 32.3337Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'SearchQueryIcon',
};
</script>
