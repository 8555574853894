<template>
  <svg class="srp-icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.222 8.254H2.369a.625.625 0 010-1.25h14.853a.625.625 0 010 1.25zM13.5 11.508a.628.628 0 01-.627-.625c0-.345.275-.625.62-.625h.008a.625.625 0 010 1.25zM9.803 11.508a.628.628 0 01-.628-.625c0-.345.276-.625.62-.625h.008a.625.625 0 010 1.25zM6.097 11.508a.629.629 0 01-.629-.625c0-.345.277-.625.622-.625h.007a.625.625 0 010 1.25zM13.5 14.747a.628.628 0 01-.627-.625c0-.345.275-.625.62-.625h.008a.625.625 0 010 1.25zM9.803 14.747a.628.628 0 01-.628-.625c0-.345.276-.625.62-.625h.008a.625.625 0 010 1.25zM6.097 14.747a.629.629 0 01-.629-.625c0-.345.277-.625.622-.625h.007a.625.625 0 010 1.25zM13.161 4.826a.625.625 0 01-.625-.625V1.458a.625.625 0 011.25 0v2.743c0 .345-.28.625-.625.625zM6.43 4.826a.625.625 0 01-.626-.625V1.458a.625.625 0 011.25 0v2.743c0 .345-.28.625-.625.625z"
    />
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.267 3.4c-2.16 0-3.35 1.152-3.35 3.244v7.541c0 2.138 1.19 3.315 3.35 3.315h7.049c2.16 0 3.35-1.155 3.35-3.252V6.644c.004-1.029-.273-1.829-.822-2.379-.565-.567-1.436-.866-2.52-.866H6.266zm7.049 15.35H6.267c-2.837 0-4.6-1.75-4.6-4.565v-7.54c0-2.774 1.763-4.496 4.6-4.496h7.056c1.424 0 2.602.427 3.406 1.233.78.784 1.192 1.911 1.188 3.264v7.602c0 2.777-1.764 4.502-4.601 4.502z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon',
};
</script>
