<template>
  <div>
    <div class="print-container">
      <v-card class="print-container__content" :width="945">
        <div class="print-container__qr-code">
          <Vue-qr-code
            :value="String(printForm.ID)"
            :width="591"
          />
        </div>
        <h1 class="print-container__title">{{ $t('Scan the QR code to pass through') }}</h1>
        <hr class="print-container__line">
        <div class="print-container__box">
          <v-icon size="66" class="print-container__icon">
            $location
          </v-icon>
          <div>
            <div class="print-container__label">{{ $t('Name of visited objects') }}</div>
            <div class="print-container__text">{{ printForm.OBJECT_NAME }}</div>
          </div>
        </div>

        <div class="print-container__box">
          <v-icon size="66" class="print-container__icon">
            $profile
          </v-icon>
          <div>
            <div class="print-container__label">{{ $t('Full name of the visitor') }}</div>
            <div class="print-container__text">{{ printForm.DATA.V_NAME }}</div>
          </div>
        </div>

        <div class="print-container__box">
          <v-icon size="66" class="print-container__icon">
            $calendar
          </v-icon>
          <div>
            <div class="print-container__label">{{ $t('Period') }}</div>
            <div class="print-container__text">
              {{ $t('From') }}
              {{ dateHelpers.getFormattedDateCustom(printForm.DATE_BEGIN) }}
              {{ $t('to') }}
              {{ dateHelpers.getFormattedDateCustom(printForm.DATE_END) }}
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import VueQrCode from 'vue-weblineindia-qrcode';
import dateHelpers from '@/helpers/dateHelpers';

export default {
  name: 'printForm',
  components: { VueQrCode },
  props: {
    printForm: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      dateHelpers,
    }
  },
};
</script>

<style lang="scss">
.print-container {
  width: 100%;
  min-height: 100vh;
  font-family: Rubik;
  background-color: $white;

  &__content {
    padding: 39px 34px;
  }

  &__qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 72px;
    font-size: 40px;
    font-weight: 500;
    color: $text-black;
    text-align: center;
  }

  &__line {
    margin-bottom: 42px;
  }

  &__box {
    display: flex;
    gap: 24px;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 42px;
    }
  }

  & &__icon {
    color: $primary;
  }

  &__label {
    font-size: 28px;
    line-height: 140%;
    color: $text-secondary-gray;
  }

  &__text {
    font-size: 40px;
    font-weight: 500;
    line-height: 140%;
    color:  $text-black;
  }
}
</style>
