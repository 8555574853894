<script>
import {
  REQUEST_STATUSES,
} from '@/constants/index.js';
import { findObjectInArrayAndGetItsValue } from '@/helpers/helpers.js';

export default {
  name: 'AppStatusList',
  props: {
    requestStatus: {
      type: Array,
      required: true
    },
    activeStatus: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      REQUEST_STATUSES,
    }
  },
  computed: {
    computedStatuses() {
      return this.requestStatus.map(status => {
        const foundStatus = findObjectInArrayAndGetItsValue({
          arr: this.REQUEST_STATUSES,
          obj: status,
          arrKey: 'value',
          objKey: 'value'
        });

        return {
          ...status,
          colorClassName: foundStatus?.colorClassName || '',
          backgroundColor: foundStatus?.backgroundColor || '',
          border: this.activeStatus.value === status.value ? foundStatus?.border || '' : ''
        };
      });
    }
  }
}
</script>

<template>
  <div
    class="status-list__search-status-list"
  >
    <div
      class="status-list__search-status-item"
      v-for="status in computedStatuses"
      :key="status.value"
      :class="[
        status.colorClassName,
        status.backgroundColor,
        status.border,
      ]"
      @click="$emit('clickStatusFilter', status)"
    >
      <div class="status-list__search-status-text">
        {{ $t(status.text) }}
      </div>
      <div class="status-list__search-status-number">
        {{ status.count }}
      </div>
    </div>
  </div>
</template>


<style lang="scss">

.status-list {
  &__search-status-list {
    display: flex;
    gap: 12px;
    width: 100vw;
    padding-left: 16px;
    margin: 12px -32px 0;
    overflow: auto;
    -ms-overflow-style: none;  /* IE и Edge */
    scrollbar-width: none;  /* Firefox */
  }

  &__search-status-list::-webkit-scrollbar {
    display: none;
  }

  &__search-status-item {
    display: flex;
    padding: 4px 8px;
    border-radius: 50px;
  }

  &__search-status-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
  }

  &__search-status-number {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    min-height: 16px;
    padding: 1px 4px;
    font-size: 10px;
    line-height: 16px;
    background: #fff;
    border-radius: 50px;
  }
}


</style>
