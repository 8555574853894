<template>
  <v-form
    class="request-form"
    ref="requestForm"
    v-model="requestFormValid"
    lazy-validation
    @keyup.native.enter="submit"
  >
    <v-card class="pa-8" :outlined="outlined">
      <v-card-title class="text-center">
          <v-icon
            @click="clickPrev()"
            class="request-form__arrow-prev"
            size="24"
          >
            $arrowIcon
          </v-icon>
        <h3
          class="request-form__title"
          :class="computedTitleClass"
        >
          {{ isNewRequestForm ? $t("New request") : $t("Request №") + value.ID }}
        </h3>
      </v-card-title>
      <AppUiInfoBadge>
        {{ object.NAME }}
      </AppUiInfoBadge>
      <v-divider />
      <v-card-text class="text--primary pt-8">
        <h4 class="text-h3 mb-5">
          {{ $t("Visit data") }}
        </h4>
        <v-menu
          v-model="datePickerMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="formattedDatePickerDate"
              :rules="[rules.required]"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            >
              <template #append>
                <v-icon class="pt-1" size="20">
                  $calendar
                </v-icon>
              </template>
              <template #label>
                {{ $t("Date of visit") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="datePickerDate"
            :allowed-dates="allowedDates"
            :first-day-of-week="1"
            no-title
            scrollable
            next-icon="$next"
            :locale="$i18n.locale"
            @input="datePickerInputHandler"
          />
        </v-menu>
        <div class="text-subtitle-2 text--secondary mb-2 mt-n1">
          {{ $t("Visit time") }}<span class="red--text">*</span>
        </div>
        <time-range-picker
          v-model="passageTime"
          class="mb-2"
          :text-field-props="{ rules: [rules.required, rules.period] }"
        />
        <template v-if="!(requestFieldsConfig.length > 0 || commonConfigItems.length > 0)">
          <v-skeleton-loader v-for="item in numberSkeletons" :key="item" class="srp-field-skeleton mb-7" type="button" />
        </template>
        <template v-else>

          <v-text-field
            v-model="requestForm.data.V_NAME"
            :rules="rules.data.V_NAME"
            maxlength="200"
            outlined
          >
            <template #label>
              {{ $t('Full name') }}
              <span class="red--text">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-if="isParkingPass()"
            v-model="requestForm.code"
            :rules="rules.code"
            hide-details="auto"
            class="mb-5"
            outlined
          >
            <template #label>
              {{ $t("License plate") }}<span class="red--text">*</span>
            </template>
          </v-text-field>

          <v-autocomplete
          v-if="isParkingPass()"
          v-model="selectedСarBrand"
          :items="carsBrands"
          :rules="rules.car_brand"
          :item-value="addCurrent"
          class="mb-5"
          append-icon="$down"
          :menu-props="{ offsetY: true }"
            hide-details="auto"
            :no-data-text="$t('Data not found')"
            outlined
          >
            <template #label>
              {{ $t("Car brand") }}<span class="red--text">*</span>
            </template>
          </v-autocomplete>

          <v-autocomplete
            v-if="isParkingPass()"
            v-model="selectedСarModel"
            :items="carsModels"
            :rules="rules.car_model"
            :menu-props="{ offsetY: true }"
            :disabled="!selectedСarBrand.value"
            :item-value="addCurrent"
            class="mb-5"
            append-icon="$down"
            hide-details="auto"
            :no-data-text="$t('Data not found')"
            outlined
          >
            <template #label>
              {{ $t("Car model") }}<span class="red--text">*</span>
            </template>
          </v-autocomplete>

          <v-text-field
            v-model="requestForm.data.V_EMAIL"
            maxlength="200"
            outlined
          >
            <template #label>
              {{ $t('Email') }}
            </template>
          </v-text-field>

          <v-text-field
            v-model="requestForm.data.V_PHONE"
            v-mask="'+####################'"
            :rules="rules.data.V_PHONE"
            outlined
          >
            <template #label>
              {{ $t("Phone number") }}<span class="red--text">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="requestForm.data.NOTICE"
            maxlength="200"
            outlined
          >
            <template #label>
              {{ $t('Note') }}
            </template>
          </v-text-field>
        </template>

        <consent-personal-data-checkbox />
        <h4 v-if="commonConfigItems.length > 0" class="text-h3 mb-5">
          {{ $t("general information") }}
        </h4>
        <request-form-field
          v-for="field in commonConfigItems"
          :key="field.SID"
          v-model="requestForm.data[field.SID]"
          :field="field"
          :rules="rules"
        />
      </v-card-text>
      <v-divider class="pb-2" />
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="!requestFormValid"
          :loading="requestFormLoading"
          @click="submit"
        >
          {{ submitText ? submitText : isNewRequestForm ? $t("Register") : $t("Save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import moment from 'moment';
import api from '@/api';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import RequestFormField from './RequestFormField';
import TimeRangePicker from '@/components/blocks/TimeRangePicker';
import ConsentPersonalDataCheckbox from '@/components/blocks/ConsentPersonalDataCheckbox.vue';
import { mapGetters } from 'vuex'
import AppUiInfoBadge from '@/components/blocks/AppUiInfoBadge.vue';
import { REQUEST_TYPES } from '@/constants/index';
export default {
  name: 'RequestForm',
  components: {
    TimeRangePicker,
    RequestFormField,
    ConsentPersonalDataCheckbox,
    AppUiInfoBadge,
  },
  props: {
    value: { type: Object, default: () => {} },
    user: { type: Object, default: () => {} },
    submitText: { type: String, default: null },
    object: {type: Object, default: {}},
    hideClose: Boolean,
    hideUser: Boolean,
    outlined: Boolean,
    largeTitle: Boolean,
    preventSubmit: Boolean,
  },
  data() {
    return {
      passageTime: '',
      usersShortList: [],
      requestsConfig: [],
      requestFormLoading: false,
      requestFormValid: false,
      requestForm: {
        data: {
          V_PHONE: '',
          V_NAME: '',
          V_EMAIL: '',
          NOTICE: '',
        }
      },
      datePickerMenu: false,
      datePickerDate: '',
      selectedСarBrand: {},
      selectedСarModel: {},
      rules: {
        required: (v) => !!v || this.$t('Required field'),
        period: (v) => {
          const period = v.match(/(\d{2}:\d{2})/g);
          if (!period) return false;
          if (!(period.length >= 2)) return false;
          const firstDate = moment(
            `${this.formattedDatePickerDate} ${period[0]}`,
            'DD.MM.YYYY HH:mm',
          );
          const secondDate = moment(
            `${this.formattedDatePickerDate} ${period[1]}`,
            'DD.MM.YYYY HH:mm',
          );
          if (!firstDate.isValid() || !secondDate.isValid()) return this.$t('Wrong time to visit');
          if (!secondDate.isAfter(firstDate)) return this.$t('Incorrect visit period');
          if (this.isNewRequestForm && secondDate.isBefore(moment())) { return this.$t('You have entered a past date'); }
          return true;
        },
        data: {
          code: [(v) => !!v || `${this.$t('You need to fill in «License plate»')}.`],
          CAR_BRAND: [(v) => !!v || `${this.$t('You must select «Car brand»')}.`],
          CAR_MODEL: [(v) => !!v || `${this.$t('You must select «Car model»')}.`],
          V_NAME: [(v) => !!v || `${this.$t('It is necessary to fill in the «full name»')}.`],
          V_PHONE: [(v) => !!v || `${this.$t('You must enter «Phone number»')}.`],
        }
      },
    };
  },
  computed: {
    ...mapGetters([
      'carsGroupedByBrand',
      'carsBrands'
    ]),
    requestFieldsConfig() {
      return this.requestsConfig.filter((item) => item.IS_SYSTEM === true);
    },
    commonConfigItems() {
      return this.requestsConfig.filter((item) => item.IS_SYSTEM === false);
    },
    formattedDatePickerDate() {
      return this.datePickerDate ? moment(this.datePickerDate).format('DD.MM.YYYY') : '';
    },
    isNewRequestForm() {
      return Boolean(this.value && !this.value.ID);
    },
    canChangeUser() {
      return !(this.user && this.user.ID);
    },
    carsModels() {
      if (!this.$store.getters.carsGroupedByBrand[this.selectedСarBrand.value]?.length) {
        return [];
      }

      return this.$store.getters.carsGroupedByBrand[this.selectedСarBrand.value].map(car => ({ text: car.NAME, value: car.ID }));
    },
    numberSkeletons() {
      return this.isParkingPass() ? 7 : 4;
    },
    computedTitleClass() {
      return this.largeTitle ? 'text-h2 py-1' : 'text-h3';
    }
  },
  watch: {
    value() {
      this.$nextTick(() => this.initForm());
    },
  },
  mounted() {
    this.initForm();
    if (!this.carsBrands.length) this.$store.dispatch('getCars')
  },
  methods: {
    addCurrent(item) {
      return item
    },
    datePickerInputHandler() {
      this.datePickerMenu = false;
      /* При изменении даты обновляет поле время для валидации */
      const oldTime = this.passageTime;
      this.passageTime = '';
      this.$nextTick(() => (this.passageTime = oldTime));
    },
    allowedDates: (val) => moment(val).isAfter(moment().add(-1, 'days')),
    createRequestForm(requestModel = {}) {
      return {
        state: requestModel.STATE || null,
        user_id: null,
        date_begin: '',
        date_end: '',
        data: {
          V_PHONE: '',
          V_NAME: '',
          V_EMAIL: '',
          NOTICE: '',
        },
        multi_pass: null,
        code: null,
      };
    },
    initForm() {
      this.resetForm();
      if (this.user && this.user.ID) {
        this.usersShortList.push({ value: this.user.ID, text: this.user.NAME });
        this.requestForm.user_id = this.user.ID;
      } else if (!this.usersShortList.length > 0) {
        this.loadUsersShortList();
      } else if (this.value.USER_NAME) {
        const userByName = this.usersShortList.filter(
          (user) => user.text === this.value.USER_NAME,
        );
        if (userByName.length > 0) {
          this.requestForm.user_id = userByName[0].value;
        }
      }

      if (!this.requestsConfig.length > 0) {
        this.loadRequestsConfig();
      } else {
        this.requestsConfig.forEach((item) => {
          this.requestForm.data[item.SID] = this.value.DATA && this.value.DATA[item.SID] ? this.value.DATA[item.SID] : '';
        });
      }
      this.datePickerDate = this.value.S_DATE_DMY
        ? moment(this.value.S_DATE_DMY, 'DD.MM.YYYY').toISOString()
        : moment().toISOString();
      // TODO: сделать другое решение для хранения диапазона (сделать массив [sDate, eDate])
      this.passageTime = this.value.S_DATE_HI
        ? `с ${this.value.S_DATE_HI} до ${this.value.E_DATE_HI}`
        : '';
    },
    resetForm() {
      if (this.$refs.requestForm) this.$refs.requestForm.resetValidation();
      this.requestForm = this.createRequestForm(this.value);
    },
    loadUsersShortList() {
      api.users.getShortList().then((res) => {
        if (res.data.DATA) {
          this.usersShortList.splice(0);
          res.data.DATA.forEach((item) => {
            if (this.value.USER_NAME && this.value.USER_NAME === item.NAME) {
              this.requestForm.user_id = item.ID;
            }

            this.usersShortList.push({ value: item.ID, text: item.NAME });
          });
        }
      });
    },
    loadRequestsConfig() {
      api.requestsConfig.getAll().then((res) => {
        if (!res.data.DATA) return false;

        this.requestsConfig.splice(0);
        res.data.DATA.forEach((item) => {
          this.requestForm.data[item.SID] = this.value.DATA && this.value.DATA[item.SID] ? this.value.DATA[item.SID] : '';
          this.requestsConfig.push(item);
        });
      });
    },
    getStartDate() {
      const time = this.passageTime.match(/(\d{2}:\d{2})/g)[0];
      return `${this.formattedDatePickerDate} ${time}`;
    },
    getEndDate() {
      const time = this.passageTime.match(/(\d{2}:\d{2})/g)[1];
      return `${this.formattedDatePickerDate} ${time}`;
    },
    isParkingPass() {
      return this.object.REQUEST_TYPE === REQUEST_TYPES.find((type) => type.text === 'Parking pass').value;
    },
    submit() {
      if (!this.$refs.requestForm.validate()) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t('Validation error. Check the correctness of the entered data.'),
        );
        return false;
      }

      this.requestForm.date_begin = this.getStartDate();
      this.requestForm.date_end = this.getEndDate();
      this.requestForm.data.CAR_BRAND = this.selectedСarBrand.value;
      this.requestForm.data.CAR_MODEL = this.selectedСarModel.value;
      this.requestForm.data.CAR_BRAND_NAME = this.selectedСarBrand.text;
      this.requestForm.data.CAR_MODEL_NAME = this.selectedСarModel.text;
      if (this.preventSubmit) return this.$emit('submit-form', this.requestForm);
      this.requestFormLoading = true;
      if (this.isNewRequestForm) {
        api.requests
          .create(this.requestForm)
          .then((res) => {
            this.requestFormLoading = false;
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              type: 'success',
              text: this.$t(res.data.MESSAGE),
            });
            this.$emit('input', this.requestForm);
            this.$emit('after-send-form');
          })
          .catch((error) => {
            this.requestFormLoading = false;
            this.$store.dispatch(
              SNACK_ADD_MESSAGE,
              this.$t(error.response.data.MESSAGE) || this.$t('Unknown error!'),
            );
          });
      } else {
        api.requests
          .update(this.requestForm, this.value.ID)
          .then((res) => {
            this.requestFormLoading = false;
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              type: 'success',
              text: this.$t(res.data.MESSAGE),
            });
            this.$emit('input', this.requestForm);
            this.$emit('after-send-form');
          })
          .catch((error) => {
            this.requestFormLoading = false;
            this.$store.dispatch(
              SNACK_ADD_MESSAGE,
              this.$t(error.response.data.MESSAGE) || this.$t('Unknown error!'),
            );
          });
      }
    },
    clickPrev() {
      this.$emit('clickPrev')
    },
  },
};
</script>

<style lang="scss">
  .request-form {
    &__title {
      width: 100%;
      text-align: center;
    }

    & &__arrow-prev {
      position: absolute;

      @media (min-width: 768px) {
        display: none;
      }
    }
  }
</style>
