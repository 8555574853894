<template>
  <svg
    class="srp-icon"
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7393 15.8031C14.6159 15.6798 14.5394 15.5093 14.5401 15.321C14.5401 14.9443 14.8455 14.639 15.2221 14.639L18.6483 14.639C19.8857 14.6448 21.0506 14.164 21.9313 13.2833C23.7415 11.4731 23.7415 8.52767 21.9313 6.71749C21.0506 5.83682 19.8864 5.35529 18.6516 5.36106L15.2227 5.36117C14.8461 5.36117 14.5407 5.05585 14.5406 4.67908C14.5407 4.3023 14.8461 3.99698 15.2227 3.99698L18.649 3.9971C20.2465 3.98862 21.7558 4.61351 22.8948 5.75254C25.2367 8.09439 25.2367 11.905 22.8948 14.2468C21.7551 15.3866 20.245 16.0107 18.6445 16.003L15.2215 16.0024C15.0325 16.0024 14.8628 15.9266 14.7393 15.8031Z"
    />
    <path
      d="M3.10502 14.2464C0.76317 11.9046 0.763171 8.09401 3.10502 5.75215C4.24476 4.61242 5.75467 3.98835 7.35534 3.996L10.7784 3.99671C11.1551 3.99671 11.4604 4.30203 11.4604 4.67869C11.4604 5.05534 11.1551 5.36067 10.7784 5.36067L7.35216 5.36079C6.11473 5.35361 4.95051 5.83514 4.06926 6.71639C2.25908 8.52657 2.25908 11.472 4.06926 13.2822C4.94993 14.1629 6.11414 14.6444 7.34898 14.6386L10.7777 14.6386C11.1544 14.6386 11.4597 14.9439 11.4597 15.3206C11.4598 15.6974 11.1545 16.0027 10.7777 16.0026L7.35145 16.0027C5.75338 16.0104 4.24417 15.3856 3.10502 14.2464Z"
    />
    <path
      d="M7.37503 10.4818C7.25168 10.3584 7.17517 10.188 7.17576 9.9998C7.17587 9.62303 7.4812 9.3177 7.85785 9.3177L18.143 9.31759C18.5198 9.3177 18.8251 9.62303 18.8251 9.99968C18.825 10.3765 18.5197 10.6818 18.143 10.6818L7.85774 10.6818C7.66882 10.6817 7.4985 10.6053 7.37503 10.4818Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LinkIcon',
};
</script>
