import { keyBy } from 'lodash-es';
import api from '@/api/index.js';
import { ALLOWED_PASS_TYPES } from '@/constants/index.js';

export default {
  state: {
    config: {
      'CONFIG.DEFAULT_PASS_STATUS': {
        SID: 'CONFIG.DEFAULT_PASS_STATUS', VALUE: null,
      },
      'CONFIG.ALLOWED_PASS_TYPE': {
        SID: 'CONFIG.ALLOWED_PASS_TYPE', VALUE: ALLOWED_PASS_TYPES.singleAndMultiPass,
      },
      'CONFIG.DEFAULT_PASS_TYPE': {
        SID: 'CONFIG.DEFAULT_PASS_TYPE', VALUE: ALLOWED_PASS_TYPES.singlePass,
      },
    },
    isConfigLoaded: false,
  },
  mutations: {
    setSystemConfig(state, data) {
      state.config = data;
    },
    updateConfigItem(state, { sid, value }) {
      state.config[sid].VALUE = value;
    },
    setConfigLoaded: (state) => {
      state.isConfigLoaded = true;
    },
  },
  getters: {
    configDefaultPassStatus: (state) => state.config['CONFIG.DEFAULT_PASS_STATUS'],
    configDefaultAllowedPassType: (state) => state.config['CONFIG.ALLOWED_PASS_TYPE'],
    configDefaultPassType: (state) => state.config['CONFIG.DEFAULT_PASS_TYPE'],
  },
  actions: {
    fetchSystemConfig({ commit }) {
      api.config.getAll().then((res) => {
        const normalizedData = res.data.DATA
          .map((config) => ({ ...config, VALUE: Number(config.VALUE) }));
        commit('setSystemConfig', keyBy(normalizedData, 'SID'));
        commit('setConfigLoaded');
      });
    },
  },
};
