<template>
  <svg class="srp-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0803 18.5928H3.7793C3.3653 18.5928 3.0293 18.2568 3.0293 17.8428C3.0293 17.4288 3.3653 17.0928 3.7793 17.0928H10.0803C10.4943 17.0928 10.8303 17.4288 10.8303 17.8428C10.8303 18.2568 10.4943 18.5928 10.0803 18.5928Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.1906 8.90039H12.8906C12.4766 8.90039 12.1406 8.56439 12.1406 8.15039C12.1406 7.73639 12.4766 7.40039 12.8906 7.40039H19.1906C19.6046 7.40039 19.9406 7.73639 19.9406 8.15039C19.9406 8.56439 19.6046 8.90039 19.1906 8.90039Z"
    />
    <mask
      id="mask0_533_5805"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="5"
      width="7"
      height="7"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 5.00049H9.2258V11.1921H3V5.00049Z" />
    </mask>
    <g mask="url(#mask0_533_5805)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.113 6.5C5.224 6.5 4.5 7.216 4.5 8.097C4.5 8.977 5.224 9.692 6.113 9.692C7.003 9.692 7.726 8.977 7.726 8.097C7.726 7.216 7.003 6.5 6.113 6.5ZM6.113 11.192C4.397 11.192 3 9.804 3 8.097C3 6.39 4.397 5 6.113 5C7.83 5 9.226 6.39 9.226 8.097C9.226 9.804 7.83 11.192 6.113 11.192Z"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.3874 16.208C16.4974 16.208 15.7734 16.924 15.7734 17.804C15.7734 18.685 16.4974 19.4 17.3874 19.4C18.2764 19.4 18.9994 18.685 18.9994 17.804C18.9994 16.924 18.2764 16.208 17.3874 16.208ZM17.3874 20.9C15.6704 20.9 14.2734 19.511 14.2734 17.804C14.2734 16.097 15.6704 14.708 17.3874 14.708C19.1034 14.708 20.4994 16.097 20.4994 17.804C20.4994 19.511 19.1034 20.9 17.3874 20.9Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'FilterIcon',
};
</script>
